import React, { useState,useEffect } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import {deleteBill,getAllBills, getBillMastPDF} from "../../../lib/api-bill";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';





import { useNavigate} from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Dialog, DialogActions, DialogTitle, IconButton, MenuItem} from '@mui/material';
import { APP_BASE_PATH } from '../../../lib/api-base-paths';
import LoadingSpinner from '../../../components/common/LoadingSpinner';








const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius:'1%',
  boxShadow: 24,
  p: 4,
};



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));




function BillMaster() {
  const[rows,setRows] = useState([]);
  const [ selectedBillId, setSelectedBillId] = useState(null);
  const navigate = useNavigate();
  const [open2 ,setOpen2]= useState(false);
  const handleClose2 = ()=> setOpen2(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => navigate('/AddBill');
  const[searchText,setSearchText] = useState('');
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [ pdfData, setPdfData] = useState(null);

  const handlePDFModalClose = ()=>{
    setPdfData(null)
    setOpenPDFModal(false)};


  const handleSearch= (e)=>
  {
    setSearchText(e.target.value);
  }

  const handleEdit = (billId) => {
    navigate(`/EditBill/${billId}`)
  };

  const handleOpen2= (billId) => 
  {
    setOpen2(true);
    setSelectedBillId(billId);
  }
 
  const handleDelete=async()=>{
    try{
      setLoading(true)
       const data= await deleteBill(selectedBillId)
       fetchData()
       setLoading(false)
       setOpen2(false);
    }catch(error){
console.error("Error Deleting bills:", error);
    }finally{
      setLoading(false)
    }
  }
 
    
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await getAllBills();
        const jsonData = response.data.map((row,index) => ({
          id: row.Id,
          srNo: index+1,
          ...row,
        }));
        setRows(jsonData);
        
      } catch (error) {
        console.error("Error fetching bills:", error);
          
      }finally{
        setLoading(false)
      }
    };
    useEffect(() => {
    fetchData();

   
  }, []);


  

  const filteredRows = rows.filter((row) => {
    const billNo = row.Bill_no.toString(); 
    const CustomerName = row.CustomerName ? row.CustomerName.toLowerCase() : ''; 
  
    return (
      billNo.includes(searchText.toLowerCase()) ||
      CustomerName.includes(searchText.toLowerCase())
    );
  });
  
  

  const columns = [
    { field: 'srNo', headerName: 'Sr. No.', flex: 1 },
    { field: 'CustomerName', headerName: 'Customer Name', flex: 1 },
    { field: 'Bill_Date', headerName: 'Invoice Date', flex: 1 },
    { field: 'po_date', headerName: 'PO Date', flex: 1 },
    { field: 'Bill_no', headerName: 'Bill No', flex: 1 },
    { field: 'GrandTotalAmt', headerName: 'Amount', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 1, renderCell: (params)=>(
      <div>
      <IconButton onClick={()=> handleEdit(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton aria-label="print" onClick={()=> handlePrint(params.row.id)}>
          <PrintIcon color='warning'/>
        </IconButton>
      <IconButton onClick={()=>handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) },
    
  ];

  const handlePrint = async(id)=>{
    try {
      setLoading(true);
      const getPDF = await getBillMastPDF(id);
      setOpenPDFModal(true);
      const filepath=getPDF.data.returnpath
      setPdfData(filepath);
      
    } catch (error) {
      console.error("Error while printing:", error);
    }finally{
      setLoading(false);

    }
}

  return (
    <div>
      {loading && <LoadingSpinner/>}
<h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Bill Master</h1>

        <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
              <div style={{marginRight:"40px"}}>
                    <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </Search>
                </div>
               <div>
                    <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Customer">Create Bill</Button>
               </div>
            
        </div>


        <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
      <DataGrid
        density='compact'
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
       
      />
    </div>
    <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
         
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center'}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDelete}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
       
        </Box>
        </Modal>
      

        

    </div>
  )
}

export default BillMaster;
