
import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { createInstrument, deleteInstrument, getAllInstruments, getInstrumentById, updateInstrument } from '../../lib/api-instrument';
import LoadingSpinner from '../../components/common/LoadingSpinner';


const style = {
 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "flex",
  height: "flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Instrument = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const [searchText, setSearchText] = useState('');
  const [instrumentName, setInstrumentName] = useState('');
  const [mID, setMID] = useState(null)
  const[rows, setRows] = useState([]);
  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const[errorMessage,setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setInstrumentName(''); 
    setErrorMessage('');
  };

  const handleClose1 = () => {
    setOpen1(false);
    setInstrumentName(''); 
    setErrorMessage('');
  };

  const handleOpen2 = (InstrumentId) =>
  {
    setOpen2(true);
    setMID(InstrumentId);

  }


  
    const fetchInstruments = async () => {
      try {
        setLoading(true)
        const data = await getAllInstruments();
        
        const instrumentsWithIds = data.map((row, index) => ({
          id: row.instrument_id,
          srNo: index + 1, 
          ...row
        }));
        
        setRows(instrumentsWithIds);
      } catch (error) {
        console.error('Error fetching materials:', error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchInstruments();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  }

  const handleAddInstrument = async () =>{
      if(instrumentName.trim()==='')
      {
         setErrorMessage('Instrument cannot be left blank');
      }
      else
      {
        try {setLoading(true)
          await createInstrument({ 
            instrument_name: instrumentName,
          });
           handleClose();
           fetchInstruments();
          } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
            
              setErrorMessage(error.response.data.error);
          } else {
              console.error('Error adding material:', error);
          }         }finally{
          setLoading(false)
        }
      }
  };

  const handleDeleteInstrument = async () => {
    try {
     setLoading(true)
      await deleteInstrument(mID);
      setOpen2(false);
      fetchInstruments();
      setSearchText('');

    } catch (error) {
      console.error('Error deleting Instrument:', error);
    }finally{
          setLoading(false)
        }
  };

  const getInstrument = async (InstrumentId) => {
    handleOpen1();
    setMID(InstrumentId);
    try {
      const Instrument = await getInstrumentById(InstrumentId);
      const jsonData = Instrument.map((row)=>
        row.Instrument_name);
        setInstrumentName(jsonData)
    } catch (error) {
      console.error('Error updating Instrument:', error);
    }
  };

  const updateInstruments = async () => {
      if(instrumentName.trim()==='')
      {
          setErrorMessage('Instrument cannot be left blank');
      }
      else
      {
        try {
     setLoading(true)
          await updateInstrument(mID, { instrument_name: instrumentName });
          handleClose1();
          fetchInstruments();
          setSearchText('');
        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            
            setErrorMessage(error.response.data.error);
        } else {
            console.error('Error adding material:', error);
        }        }finally{
          setLoading(false)
        }
      }
  };

  const columns = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'Instrument_name', headerName: 'Instrument Name', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 0.25, renderCell: (params)=>(
      <div>
      <IconButton onClick={() => getInstrument(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) },

  ];

  return (
    <div>
            {loading && <LoadingSpinner/>}

    <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Instrument</h1> 
    <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
          <div style={{marginRight:"40px"}}>
                <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </Search>
            </div>
           <div>
                <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Instrument">Add Instrument</Button>
           </div>
        
    </div>


    <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
  <DataGrid
    density='compact'
    rows={rows.filter((instrument) =>
      instrument.Instrument_name && instrument.Instrument_name.toLowerCase().includes(searchText.toLowerCase())
    )}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5, 10, 20]}
   
  />
</div>

<Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
       
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Instrument Name"
            size="small"
            variant="outlined"
            value={instrumentName}
            onChange={(e) => setInstrumentName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12}  sx={{ marginTop: 2}}>
          <Button variant="contained" 
          color="success" 
          onClick={handleAddInstrument}
          fullWidth>
              Save
          </Button>
        </Grid>
        <Grid item xs={12}  sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 
    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
       
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Instrument Name"
            size="small"
            variant="outlined"
            value={instrumentName}
            onChange={(e) => setInstrumentName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12}  sx={{ marginTop: 2}}>
          <Button variant="contained" 
          color="success" 
          onClick={updateInstruments}
          fullWidth>
             Update
          </Button>
        </Grid>
        <Grid item xs={12}  sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose1}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 

    handleDeleteInstrument
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteInstrument}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
</div>
  )
}

export default Instrument;


