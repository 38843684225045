import axios from 'axios';
import { INSTRUMENT_BASE_PATH } from './api-base-paths';

export const getAllInstruments = async () => {
    try {
        const response = await axios.get(`${INSTRUMENT_BASE_PATH}/getAllInstruments`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all instruments:', error);
        throw error;
    }
};

export const createInstrument = async (instrumentData) => {
    try {
        const response = await axios.post(`${INSTRUMENT_BASE_PATH}/createInstrument`, instrumentData);
        return response.data;
    } catch (error) {
        console.error('Error creating instrument:', error);
        throw error;
    }
};

export const deleteInstrument = async (instrumentId) => {
    try {
        const response = await axios.delete(`${INSTRUMENT_BASE_PATH}/deleteInstrument/${instrumentId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting instrument:', error);
        throw error;
    }
};

export const updateInstrument = async (instrumentId, instrumentData) => {
    try {
        const response = await axios.put(`${INSTRUMENT_BASE_PATH}/updateInstrument/${instrumentId}`, instrumentData);
        return response.data;
    } catch (error) {
        console.error('Error updating instrument:', error);
        throw error;
    }
};

export const getInstrumentById = async (instrumentId) => {
    try {
        const response = await axios.get(`${INSTRUMENT_BASE_PATH}/getInstrumentById/${instrumentId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching instrument by ID:', error);
        throw error;
    }
};
