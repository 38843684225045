import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Typography} from '@mui/material';

import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { createElement, createSpgrade, deleteElement, getAllElements, getAllSpgrade, getElementById, updateElement } from '../../lib/api-element';
import LoadingSpinner from '../../components/common/LoadingSpinner';




const style = {
 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  MaxWidth: 700,
  height: "flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style2 = {
 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "flex" ,
  height: "flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};





const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const Element = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [opt,setOpt]=useState([]);
  const [open3, setOpen3] = useState(false);
  const[elements, setElements] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [elementName, setElementName] = useState('');
  const [minP, setMinP] = useState('');
  const [maxP, setMaxP] = useState('');
  const [spgrade, setSpgrade] = useState('');
  const handleOpen3 = () => setOpen3(true);
  const [open1, setOpen1] = useState(false);
  const handleOpen1 = () => setOpen1(true);
  const [loading, setLoading] = useState(false);

  const[errorMessage1,setErrorMessage1]= useState('');
const [elementErrorMessage, setElementErrorMessage] = useState('');
const [minPErrorMessage, setMinPErrorMessage] = useState('');
const [maxPErrorMessage, setMaxPErrorMessage] = useState('');
const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);  
  const [mID, setMID] = useState(null)
  const handleClose3 = () => {
    setOpen3(false);
    setElementName(''); 
    setMinP('');
    setMaxP('');
    setSpgrade('');
    setElementErrorMessage('');
    setMinPErrorMessage('');
    setMaxPErrorMessage('');
  };

  const handleClose1 = () => {
    setOpen1(false);
    setElementName(''); 
    setMinP('');
    setMaxP('');
    setSpgrade('');
    setElementErrorMessage('');
    setMinPErrorMessage('');
    setMaxPErrorMessage('');
  };
  
  const handleClose = () => {
    setOpen(false);
    setSpgrade('');
    setErrorMessage1('');
  };

  const handleOpen2 = (elementId) =>
  {
    setOpen2(true);
    setMID(elementId);

  }
   
    const fetchElements = async () =>{
      try {
        setLoading(true)
        const data = await getAllElements();
    
        const elementsWithIds = data.map((row) => ({
          id: row.Id, 
          ...row
        }));
        setElements(elementsWithIds);
      } catch (error) {
        console.error('Error fetching elements:', error);
      }finally{
        setLoading(false)
      }
    };
    useEffect(() => {
    fetchElements();
  }, []);

  
    const fetchSpgrades = async () => {
      try {
        const data = await getAllSpgrade();
        const gradeOptions = data.map((row) => ({ value: row.Id, label: row.Spgrade }));
        
        setOpt(gradeOptions);
      } catch (error) {
        console.error('Error fetching Spgrades:', error);
      }
    };
    useEffect(() => {
    fetchSpgrades();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  }

 
  const handleAddElement = async () => {
    if (elementName.trim() === '') {
      setElementErrorMessage('Element name cannot be left blank');
      return;
    } else {
      setElementErrorMessage('');
    }
  
    if (minP.trim() === '') {
      setMinPErrorMessage('Minimum percentage cannot be left blank');
      return;
    } else {
      setMinPErrorMessage('');
    }
  
    if (maxP.trim() === '') {
      setMaxPErrorMessage('Maximum percentage cannot be left blank');
      return;
    } else {
      setMaxPErrorMessage('');
    }
    try {
      setLoading(true)
      await createElement({ 
        Element: elementName,
        MinP: minP + '%',
        MaxP: maxP + '%',
        Spid: spgrade?.value, 
      });
      handleClose3();
      fetchElements();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
            
        setElementErrorMessage(error.response.data.error);
    } else {
        console.error('Error adding material:', error);
    }    }finally{
      setLoading(false)
    }
  };

  const handleAddSpgrade = async () =>{
   if(spgrade.trim()==='')
   {
      setErrorMessage1("Grade cannot be left blank")
   }
   else
   {
      setErrorMessage1('')
      try {
        setLoading(true)
        await createSpgrade({ 
          Spgrade: spgrade,
        });
        handleClose();
        fetchSpgrades();
        
       } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            
          setErrorMessage1(error.response.data.error);
      } else {
          console.error('Error adding material:', error);
      }       }finally{
        setLoading(false)
      }
   }
  };

  const handleDeleteElement = async () => {
    try {
      setLoading(true)
      await deleteElement(mID);
      setOpen2(false);
      fetchElements();
      setSearchText('');
    } catch (error) {
      console.error('Error deleting Element:', error);
    }finally{
      setLoading(false)
    }
  };

  const getElement = async (elementId) => {
    handleOpen1();
    setMID(elementId);
    try {
      const Element = await getElementById(elementId);
      
        Element.map((row)=>{
        setElementName(row.Element);
        setMinP(row.MinP);
        setMaxP(row.MaxP);
        const selectedOption = opt.find(option => option.value === row.Spid);
        setSpgrade(selectedOption);
      })
     
      
    } catch (error) {
      console.error('Error updating Element:', error);
    }
  };

  const updateElements = async () => {
    if (elementName.trim() === '') {
      setElementErrorMessage('Element name cannot be left blank');
      return;
    } else {
      setElementErrorMessage('');
    }
  
    if (minP.trim() === '') {
      setMinPErrorMessage('Minimum percentage cannot be left blank');
      return;
    } else {
      setMinPErrorMessage('');
    }
  
    if (maxP.trim() === '') {
      setMaxPErrorMessage('Maximum percentage cannot be left blank');
      return;
    } else {
      setMaxPErrorMessage('');
    }
    try {
      const updatedElementData = {
        
        Element: elementName,
        MinP:minP ,
        MaxP:maxP ,
        Spid:spgrade?.value,
        
      };
      setLoading(true)
      await updateElement(mID, updatedElementData);
      handleClose1();
      fetchElements();
      setSearchText('');    
         } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            
            setElementErrorMessage(error.response.data.error);
        } else {
            console.error('Error adding material:', error);
        }    }finally{
          setLoading(false)
  };
  };
  function filterelements(element, searchText) {
    return (
      (element.Element && element.Element.toLowerCase().includes(searchText.toLowerCase())) ||
      (element.MinP && element.MinP.toString().includes(searchText)) ||
      (element.MaxP && element.MaxP.toString().includes(searchText))
    );
    
  }


  const columns= [
    { field: 'Element', headerName: 'Element Name', flex: 1 },
    { field: 'MinP', headerName: 'Minimum Percentage', flex: 1 },
    { field: 'MaxP', headerName: 'Maximum Percentage', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 0.4, renderCell: (params)=>(
      <div>
      <IconButton onClick={() => getElement(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>) },
  
  ];

  return (
    <div>
      {loading && <LoadingSpinner/>}
        <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Element</h1> 
    <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
          <div style={{marginRight:"40px"}}>
                <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </Search>
            </div>
           <div style={{marginRight:"20px"}}>
                <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Grade">Add Grade</Button>
           </div>
           <div>
                <Button onClick={handleOpen3}  style={{backgroundColor:"red",color:"white"}} variant="Add Element">Add Element</Button>
           </div>
        
    </div>


    <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
  <DataGrid
    density='compact'
    rows={elements.filter((element) => filterelements(element, searchText))}
    columns={columns}
    pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
   
  />
</div>

<Modal
      sx={{ maxWidth: 800, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
       
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Grade"
            size="small"
            variant="outlined"
            value={spgrade}
            onChange = {(e) => setSpgrade(e.target.value)}
            fullWidth
            error={errorMessage1 !== ''}
            helperText={errorMessage1}
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" 
          color="success" 
          onClick={handleAddSpgrade}
          fullWidth>
              Save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 

    <Modal
      sx={{ maxWidth: 800, margin: "auto", marginTop: 2 }}
      open={open3}
      onClose={handleClose3}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
        <Autocomplete
  options={opt}
  getOptionLabel={(option) => option?.label || ""} 
  getOptionValue={(option) => option?.value || ""} 
  value={spgrade}
  onChange={(event, newValue) => {
    setSpgrade(newValue);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      className="customTextField"
      id="outlined-basic"
      size="small"
      label="Select or type Grade"
      variant="outlined"
    />
  )}
/>

        </Grid>
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label=" Element Name"
            size="small"
            variant="outlined"
            value={elementName}
            onChange={(e)=> setElementName(e.target.value)}
            error={elementErrorMessage !== ''}
            helperText={elementErrorMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Minimum Percentage"
            size="small"
            variant="outlined"
            value={minP}
            onChange={(e)=> setMinP(e.target.value)}
            error={minPErrorMessage !== ''}
            helperText={minPErrorMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Maximum Percentage"
            size="small"
            variant="outlined"
            value={maxP}
            onChange={(e)=> setMaxP(e.target.value)}
            error={maxPErrorMessage !== ''}
            helperText={maxPErrorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" 
          color="success" 
          onClick={handleAddElement}
          fullWidth>
              save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose3}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>
    <Modal
      sx={{ maxWidth: 800, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style2}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
        <Autocomplete
  options={opt}
  getOptionLabel={(option) => option?.label} 
  getOptionValue={(option) => option?.value} 
  value={spgrade}
  onChange={(event, newValue) => {
    setSpgrade(newValue);
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      className="customTextField"
      id="outlined-basic"
      size="small"
      label="Select or type Grade"
      variant="outlined"
    />
  )}
/>
        </Grid>
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label=" Element Name"
            size="small"
            variant="outlined"
            value={elementName}
            onChange={(e)=> setElementName(e.target.value)}
            error={elementErrorMessage !== ''}
            helperText={elementErrorMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Minimum Percentage"
            size="small"
            variant="outlined"
            value={minP}
            onChange={(e)=> setMinP(e.target.value)}
            error={minPErrorMessage !== ''}
            helperText={minPErrorMessage}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Maximum Percentage"
            size="small"
            variant="outlined"
            value={maxP}
            onChange={(e)=> setMaxP(e.target.value)}
            error={maxPErrorMessage !== ''}
            helperText={maxPErrorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" 
          color="success" 
          onClick={updateElements}
          fullWidth>
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose1}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>
      
    

    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteElement}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
</div>
  )
}




export default Element;
