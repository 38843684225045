// export const APP_BASE_PATH = "http://localhost:8800/";
// export const MATERIAL_BASE_PATH = "http://localhost:8800/api/material";
// export const CUSTOMER_BASE_PATH = "http://localhost:8800/api/customer";
// export const NEFT_BANK_BASE_PATH = "http://localhost:8800/api/neft_bank";
// export const ELEMENT_BASE_PATH = "http://localhost:8800/api/element";
// export const BRANCH_BASE_PATH = "http://localhost:8800/api/branch";
// export const INSTRUMENT_BASE_PATH = "http://localhost:8800/api/instrument";
// export const UT_BASE_PATH = "http://localhost:8800/api/ut";
// export const TECHNIQUE_BASE_PATH = "http://localhost:8800/api/technique";
// export const PROBE_BASE_PATH = "http://localhost:8800/api/probe";
// export const BILL_BASE_PATH = "http://localhost:8800/api/billMaster";
// export const DYE_BASE_PATH = "http://localhost:8800/api/dye";
// export const EDDY_BASE_PATH = "http://localhost:8800/api/eddy";
// export const MAGNETIC_BASE_PATH = "http://localhost:8800/api/magnetic";
// export const SPARK_BASE_PATH = "http://localhost:8800/api/spark";
// export const ULTRASONIC_BASE_PATH = "http://localhost:8800/api/ultrasonic";
// export const LOGIN_BASE_PATH = "http://localhost:8800/api/login";



export const APP_BASE_PATH = "https://api.llp.sadhanandt.in/";
export const LOGIN_BASE_PATH = "https://api.llp.sadhanandt.in/api/login";
export const MATERIAL_BASE_PATH = "https://api.llp.sadhanandt.in/api/material";
export const CUSTOMER_BASE_PATH = "https://api.llp.sadhanandt.in/api/customer";
export const NEFT_BANK_BASE_PATH = "https://api.llp.sadhanandt.in/api/neft_bank";
export const ELEMENT_BASE_PATH = "https://api.llp.sadhanandt.in/api/element";
export const BRANCH_BASE_PATH = "https://api.llp.sadhanandt.in/api/branch";
export const INSTRUMENT_BASE_PATH = "https://api.llp.sadhanandt.in/api/instrument";
export const UT_BASE_PATH = "https://api.llp.sadhanandt.in/api/ut";
export const TECHNIQUE_BASE_PATH = "https://api.llp.sadhanandt.in/api/technique";
export const PROBE_BASE_PATH = "https://api.llp.sadhanandt.in/api/probe";
export const BILL_BASE_PATH = "https://api.llp.sadhanandt.in/api/billMaster";
export const DYE_BASE_PATH = "https://api.llp.sadhanandt.in/api/dye";
export const EDDY_BASE_PATH = "https://api.llp.sadhanandt.in/api/eddy";
export const MAGNETIC_BASE_PATH = "https://api.llp.sadhanandt.in/api/magnetic";
export const SPARK_BASE_PATH = "https://api.llp.sadhanandt.in/api/spark";
export const ULTRASONIC_BASE_PATH = "https://api.llp.sadhanandt.in/api/ultrasonic";



// export const APP_BASE_PATH = "https://api.llp.cloudbin.in/";
// export const LOGIN_BASE_PATH = "https://api.llp.cloudbin.in/api/login";
// export const MATERIAL_BASE_PATH = "https://api.llp.cloudbin.in/api/material";
// export const CUSTOMER_BASE_PATH = "https://api.llp.cloudbin.in/api/customer";
// export const NEFT_BANK_BASE_PATH = "https://api.llp.cloudbin.in/api/neft_bank";
// export const ELEMENT_BASE_PATH = "https://api.llp.cloudbin.in/api/element";
// export const BRANCH_BASE_PATH = "https://api.llp.cloudbin.in/api/branch";
// export const INSTRUMENT_BASE_PATH = "https://api.llp.cloudbin.in/api/instrument";
// export const UT_BASE_PATH = "https://api.llp.cloudbin.in/api/ut";
// export const TECHNIQUE_BASE_PATH = "https://api.llp.cloudbin.in/api/technique";
// export const PROBE_BASE_PATH = "https://api.llp.cloudbin.in/api/probe";
// export const BILL_BASE_PATH = "https://api.llp.cloudbin.in/api/billMaster";
// export const DYE_BASE_PATH = "https://api.llp.cloudbin.in/api/dye";
// export const EDDY_BASE_PATH = "https://api.llp.cloudbin.in/api/eddy";
// export const MAGNETIC_BASE_PATH = "https://api.llp.cloudbin.in/api/magnetic";
// export const SPARK_BASE_PATH = "https://api.llp.cloudbin.in/api/spark";
// export const ULTRASONIC_BASE_PATH = "https://api.llp.cloudbin.in/api/ultrasonic";
