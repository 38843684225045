import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateEddy from "./CreateEddy";
  
  const AddEddy = () => {
    const { eddyId } = useParams();
    const isEdit = eddyId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{eddyId === undefined ? "Add Eddy" : "Edit Eddy"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateEddy eddyId={eddyId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddEddy;
  