import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateMagnetic from "./CreateMagnetic";
  
  const AddMagnetic = () => {
    const { magneticId } = useParams();
    const isEdit = magneticId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{magneticId === undefined ? "Add Magnetic" : "Edit Magnetic"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateMagnetic magneticId={magneticId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddMagnetic;
  