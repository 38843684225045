import axios from 'axios';
import { NEFT_BANK_BASE_PATH } from './api-base-paths';

export const getAllNeft_bank = async () => {
    try {
        const response = await axios.get(`${NEFT_BANK_BASE_PATH}/getAllNeftBanks`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all neft_bank:', error);
        throw error;
    }
};

export const createNeft_bank = async (neft_bankData) => {
    try {
        const response = await axios.post(`${NEFT_BANK_BASE_PATH}/createNeftBank`, neft_bankData);
        return response.data;
    } catch (error) {
        console.error('Error creating neft_bank:', error);
        throw error;
    }
};

export const deleteNeft_bank = async (account_no) => {
    try {
        const response = await axios.delete(`${NEFT_BANK_BASE_PATH}/deleteNeftBank/${account_no}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting neft_bank:', error);
        throw error;
    }
};

export const updateNeft_bank = async (neft_bankId, neft_bankData) => {
    try {
        const response = await axios.put(`${NEFT_BANK_BASE_PATH}/updateNeftBank/${neft_bankId}`, neft_bankData);
        return response.data;
    } catch (error) {
        console.error('Error updating neft_bank:', error);
        throw error;
    }
};

export const getNeft_bankById = async (neft_bankId) => {
    try {
        const response = await axios.get(`${NEFT_BANK_BASE_PATH}/getNeft_bankById/${neft_bankId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching neft_bank by ID:', error);
        throw error;
    }
};
