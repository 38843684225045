import axios from 'axios';
import { DYE_BASE_PATH } from './api-base-paths';

export const getAllDyes = () => {
  try {
    return axios.get(`${DYE_BASE_PATH}/getAllDyes`);
  } catch (error) {
    console.error('Error fetching all Dyes', error);
    throw error;
  }
};

export const createDye = (data) => {
    return axios.post(`${DYE_BASE_PATH}/createDye`, data);
  };
 
  export const getDyeByCertificateNo = (id) => {
    return axios.get(`${DYE_BASE_PATH}/getDyeByCertificateNo/${id}`);
  }; 


  export const getAutoCertificateNo = () => {
    return axios.get(`${DYE_BASE_PATH}/getAutoCertificateNo`);
  }; 
 

  export const getDyeById = (id) => {
    return axios.get(`${DYE_BASE_PATH}/getDyeById/${id}`);
  }; 

  export const updateDye = (id, data) => {
    return axios.put(`${DYE_BASE_PATH}/updateDye/${id}`, data);
  };

  export const deleteDye = (id) => {
    return axios.delete(`${DYE_BASE_PATH}/deleteDye/${id}`);
  };  
  export const getPrint = async () => {
    try {
        const response = await axios.get(`${DYE_BASE_PATH}/getPrint`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};

  export const createDyeDescription = (data) => {
    return axios.post(`${DYE_BASE_PATH}/createDyeDescription`, data);
  };

  export const deleteDyeDescription = (id) => {
    return axios.delete(`${DYE_BASE_PATH}/deleteDyeDescription/${id}`);
};
  export const getDyeDescriptionById = (id) => {
    return axios.get(`${DYE_BASE_PATH}/getDyeDescriptionById/${id}`);
  }; 
  

  export const getDyePDF = (id) => {
    return axios.put(`${DYE_BASE_PATH}/getDyePDF/${id}`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
  };

  export const getLatestId = () => {
    return axios.get(`${DYE_BASE_PATH}/LatestId`);
  }; 