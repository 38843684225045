import axios from 'axios';
import { BILL_BASE_PATH } from './api-base-paths';

export const addBill = (data) => {
    return axios.post(`${BILL_BASE_PATH}/createBillMast`, data);
  };

  export const getAllBills = () => {
    try {
      return axios.get(`${BILL_BASE_PATH}/getAllBill`);
    } catch (error) {
      console.error('Error fetching all Bills', error);
      throw error;
    }
  };

  export const getAutoBill_no = () => {
    return axios.get(`${BILL_BASE_PATH}/getAutoBill_no`);
  }; 


export const getBillMastByDate = (data) => {
  return axios.post(`${BILL_BASE_PATH}/getBillMastByDate`, data
    );
};

export const getCertificateNo = (data) => {
  return axios.post(`${BILL_BASE_PATH}/getCertificateNo`, data
    );
};

export const getReferance_Id = (data) => {
  return axios.post(`${BILL_BASE_PATH}/getReferance_Id`, data
    );
};


export const getDescriptionDataByCertificateNo = (data) => {
  return axios.post(`${BILL_BASE_PATH}/getDescriptionDataByCertificateNo`, data
    );
};

  export const getBillById = (id) => {
    return axios.get(`${BILL_BASE_PATH}/getBillMastbyBillNo/${id}`);
  }; 

  export const updateBill = (id,data) => {
    
    return axios.put(`${BILL_BASE_PATH}/updateBillMast/${id}`, data);
  };

  export const deleteBill = (id) => {
    return axios.delete(`${BILL_BASE_PATH}/deleteBill/${id}`);
  };    

  export const getBillMastPDF = (id) => {
    return axios.get(`${BILL_BASE_PATH}/getBillMastPDF/${id}`);
  };

  export const getPrint = async () => {
    try {
        const response = await axios.get(`${BILL_BASE_PATH}/getPrint`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};