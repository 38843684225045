import axios from 'axios';
import { TECHNIQUE_BASE_PATH } from './api-base-paths';

export const getAllTechnique = async () => {
    try {
        const response = await axios.get(`${TECHNIQUE_BASE_PATH}/getAllTechniques`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all technique:', error);
        throw error;
    }
};

export const createTechnique = async (techniqueData) => {
    try {
        const response = await axios.post(`${TECHNIQUE_BASE_PATH}/createTechnique`, techniqueData);
        return response.data;
    } catch (error) {
        console.error('Error creating technique:', error);
        throw error;
    }
};

export const deleteTechnique = async (techniqueId) => {
    try {
        const response = await axios.delete(`${TECHNIQUE_BASE_PATH}/deleteTechnique/${techniqueId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting technique:', error);
        throw error;
    }
};

export const updateTechnique = async (techniqueId, techniqueData) => {
    try {
        const response = await axios.put(`${TECHNIQUE_BASE_PATH}/updateTechnique/${techniqueId}`, techniqueData);
        return response.data;
    } catch (error) {
        console.error('Error updating technique:', error);
        throw error;
    }
};

export const getTechniqueById = async (techniqueId) => {
    try {
        const response = await axios.get(`${TECHNIQUE_BASE_PATH}/getTechniqueById/${techniqueId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching technique by ID:', error);
        throw error;
    }
};
