import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from "./components/Login/Login"

import Layout from "./components/Layout/Layout"
import CustomRoutes from './components/Routes/CustomRoutes';

import { useEffect, useState } from 'react';



function App() {
  
  const [isAuthenticated, setAuthenticated] = useState(localStorage.getItem('login'));
  
  const storage = localStorage.getItem('token');
  const token = storage && storage.split('; ').find(row => row.startsWith('token='));
  useEffect(() => {
    if (token) {  
    localStorage.setItem('login', true) 
    setAuthenticated(true); // Dispatch login action if token is present
  }
}, [token]) 
  return (
     <Router basename={process.env.PUBLIC_URL}>
<Routes>
<Route index element={<Login/>} />
  <Route path="/*" element={
  
    <Layout/>
  
  } />
</Routes>
</Router> 
  );
}
{/* <Router basename={process.env.PUBLIC_URL}>
<Routes>
<Route index element={<Login/>} />
  <Route path="/*" element={
  <ProtectedRoute isAuthenticated={isAuthenticated}>
    <CustomRoutes />
  </ProtectedRoute>
  } />
</Routes>
</Router> */}

export default App;