import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { createTechnique, deleteTechnique, getAllTechnique, getTechniqueById, updateTechnique } from '../../lib/api-technique';
import LoadingSpinner from '../../components/common/LoadingSpinner';




const style = {
 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "flex",
  height: "flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Technique = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);

  const[rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [techniqueName, setTechniqueName] = useState('');
  const [mID, setMID] = useState(null);
  const [loading, setLoading] = useState(false);

  const[errorMessage,setErrorMessage]= useState('');

  const handleClose = () => {
    setOpen(false);
    setTechniqueName(''); 
    setErrorMessage('');
  };

  const handleClose1 = () => {
    setOpen1(false);
    setTechniqueName(''); 
    setErrorMessage('');
  };

  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (TechniqueId) =>
  {
    setOpen2(true);
    setMID(TechniqueId);

  }


    const fetchTechnique = async () => {
      try {
        setLoading(true)
        const data = await getAllTechnique();
        
        const techniquesWithIds = data.map((row, index) => ({
          id: row.tech_id,
          srNo: index + 1, 
          ...row
        }));
        
        setRows(techniquesWithIds);
      } catch (error) {
        console.error('Error fetching technique:', error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchTechnique();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  }

  const handleAddTechnique = async () =>{
         if(techniqueName.trim()==='')
         {
            setErrorMessage("Technique cannot be left blank")
         }
         else
         {
          setErrorMessage('');
          try {
            setLoading(true)
            await createTechnique({ 
              tech_name: techniqueName,
            });
             handleClose();
             fetchTechnique();
            } catch (error) {
              if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error adding technique:', error);
            }           }finally{
          setLoading(false)
        }
         }
  };

  const handleDeleteTechnique = async () => {
    try {
     setLoading(true)
      await deleteTechnique(mID);
      setOpen2(false);
      fetchTechnique();
      setSearchText('');

    } catch (error) {
      console.error('Error deleting Technique:', error);
    }finally{
          setLoading(false)
        }
  };

  const getTechnique = async (TechniqueId) => {
    handleOpen1();
    setMID(TechniqueId);
    try {
      const Technique = await getTechniqueById(TechniqueId);
      const jsonData = Technique.map((row)=>
        row.tech_name);
        setTechniqueName(jsonData)
    } catch (error) {
      console.error('Error updating Technique:', error);
    }
  };

  const updateTechniques = async () => {
     if(techniqueName.trim()==='')
     {
        setErrorMessage('Technique cannot be left blank');
     }
     else
     {
      setErrorMessage('');
      try {
     setLoading(true)
        await updateTechnique(mID, { tech_name: techniqueName });
        handleClose1();
        fetchTechnique();
        setSearchText('');
  
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          setErrorMessage(error.response.data.error);
      } else {
          console.error('Error updating technique:', error);
      }      }finally{
          setLoading(false)
        }
     }
  };

  const columns = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'tech_name', headerName: 'Technique Name', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 0.25, renderCell: (params)=>(
      <div>
      <IconButton onClick={() => getTechnique(params.row.id)} >
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.id)} >
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    )},
   
  ];

  return (
    <div>
      {loading && <LoadingSpinner/>}
        <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Technique</h1> 

        <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
              <div style={{marginRight:"40px"}}>
                    <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </Search>
                </div>
               <div>
                    <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Technique">Add Technique</Button>
               </div>
            
        </div>


        <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
      <DataGrid
        density='compact'
        rows={rows.filter((technique) =>
          technique.tech_name && technique.tech_name.toLowerCase().includes(searchText.toLowerCase())
        )}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
       
      />
    </div>

    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Technique Name"
            size="small"
            variant="outlined"
            value={techniqueName}
            onChange={(e) => setTechniqueName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12}  sx={{ marginTop: 2}}>
          <Button variant="contained" 
          color="success" 
          onClick={handleAddTechnique} 
          fullWidth>
              Save
          </Button>
        </Grid>
        <Grid item xs={12}  sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 
    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Technique Name"
            size="small"
            variant="outlined"
            value={techniqueName}
            onChange={(e) => setTechniqueName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12}  sx={{ marginTop: 2}}>
          <Button variant="contained" 
          color="success" 
          onClick={updateTechniques}
          fullWidth>
              Update
          </Button>
        </Grid>
        <Grid item xs={12}  sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose1}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>      
    
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteTechnique}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
    </div>
  )
}

export default Technique;



