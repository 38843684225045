import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid} from '@mui/x-data-grid';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, MenuItem, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import {getAllBranch,createBranch,deleteBranch, getBranchById,updateBranch} from '../../lib/api-branch';
import LoadingSpinner from '../../components/common/LoadingSpinner';



const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "flex",
  height:"flex",
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


const Branch = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const[open1,setOpen1] =useState(false);
  const handleEdit = () =>setOpen1(true);
  const[rows, setRows] = useState([]);
  const[branchName,setBranchName] = useState('');
  const [searchText, setSearchText] = useState('');
  const[bID,setBid] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (branchId) =>
  {
    setOpen2(true);
    setBid(branchId);

  }
  const handleEditClose = () => {
    setOpen1(false);
    setBranchName(''); 
    setErrorMessage('');
  };
  
  const handleClose = () => {
    setOpen(false);
    setBranchName(''); 
    setErrorMessage('');
  };

  const handleSearch = (event) =>
  {
    setSearchText(event.target.value);
    
  }


  
  
    const fetchBranchs = async() =>
    {
        try
        {
          setLoading(true)
           const data = await getAllBranch();
          
           const branchsWithIds = data.map((row,index) => (
            {
              id:row.branch_id,
              srNo:index+1,
              ...row
            }
           ));
           
         setRows(branchsWithIds);
        }
        catch(error)
        {
           console.log("Error Fetching Branchs" , error);
        }finally{
          setLoading(false)
        }
    };

    useEffect(() =>
  {
    fetchBranchs();
  }, []);

  const handleAddBranch = async() =>
  {
     if (branchName.trim()==='')
     {
      setErrorMessage('Branch name cannot be left blank');
     }
     else
     {
      setErrorMessage('');
      try
      {
        setLoading(true)
        await createBranch({branch_name:branchName});
        handleClose();
        fetchBranchs();
      }
      catch(error)
      {
        if (error.response && error.response.data && error.response.data.error) {
            
          setErrorMessage(error.response.data.error);
      } else {
          console.error('Error adding material:', error);
      }
      }finally{
        setLoading(false)
      }
     }
  }

  const handleDeleteBranch = async() => 
  {
      try
      {
        setLoading(true)
        await deleteBranch(bID);
        setOpen2(false);
        fetchBranchs();
        setSearchText('');
      }
      catch(error)
      {
        console.log('Error Deleting branch',error);
      }finally{
        setLoading(false)
      }
  }

  const getBranch = async (branchId) => {
    handleEdit();
    setBid(branchId);
    try {
      const branch = await getBranchById(branchId); 
      const jsonData = branch.map((row) => row.branch_name);
      setBranchName(jsonData);
    } catch (error) {
      console.log('Error in getting branch by id', error);
    }
  };
    
  
  const updateBranchs = async() =>
  {
    if(branchName.trim() === '')
    {
        setErrorMessage("Branch Name cannot be left blank")
    }
    else
    {
      setErrorMessage('');
      try
      {
        setLoading(true)
        await updateBranch(bID, { branch_name: branchName });
        fetchBranchs();
        handleEditClose();
        setSearchText('');
  
      }
      catch(error)
      {
        if (error.response && error.response.data && error.response.data.error) {
            
          setErrorMessage(error.response.data.error);
      } else {
          console.error('Error adding material:', error);
      }      }finally{
        setLoading(false)
      }
    }
  }

  const columns = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'branch_name', headerName: 'Branch Name', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 0.24, renderCell: (params)=>(
      <div>
      <IconButton onClick={()=> getBranch(params.row.id) } >
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={()=> handleOpen2(params.row.id) }>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) }, 
   
  ];

  
  
  
  return (
    <Grid>
      {loading && <LoadingSpinner/>}
      <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Branch</h1>  
    <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
          <div style={{marginRight:"40px"}}>
                <Search>
                  
                      <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                   
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchText}
                      onChange={handleSearch}
                    />
                  </Search>
            </div>
           <div>
                <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Branch">Add Branch</Button>
           </div>
        
    </div>


    <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
  <DataGrid
    density='compact'
    rows={rows.filter((branch) =>
      branch.branch_name && branch.branch_name.toLowerCase().includes(searchText.toLowerCase())
    )}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5, 10, 20]}
   
  />
</div>


<Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
      
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Branch"
            size="small"
            value={branchName}
            onChange={(e)=>setBranchName(e.target.value)}
            variant="outlined"
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" fullWidth onClick={handleAddBranch}>
              Save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 



    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleEditClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
      
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Branch"
            size="small"
            value={branchName}
            onChange={(e)=>setBranchName(e.target.value)}
            variant="outlined"
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" fullWidth onClick={updateBranchs}>
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleEditClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 
    
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteBranch}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
</Grid>
  )
}

export default Branch