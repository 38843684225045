import React from 'react'
import logo from "../assets/logo.jpeg";

function Db() {
  return (
    <div style={{display :"flex" , flexDirection:"column"}} >
      <div style={{display:"flex",alignItems:"center", justifyContent:"center",}}>
      <img src={logo} alt="xxx" width={"400px"} style={{background:"white"}}  />
      </div>
       <h1 style={{textAlign:"center",color:"red", fontFamily:"Overpass ,serif"}}>SADHANA NDT SERVICES LLP</h1>
       <p style={{marginLeft:"70%",fontSize:"12px", marginTop:"50px" , fontFamily:"Overpass ,serif"}}>2024 &copy; <b> Vspace Software</b> - Save Your Time, Choose The Best</p>
    </div>
    
  )
}

export default Db;
