import React from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';

import {Link} from 'react-router-dom';

import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';






const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const columns= [
  { field: 'id', headerName: 'Sr. No.', flex: 1 },
  { field: 'firstName', headerName: 'Customer Name', flex: 1 },
  { field: 'cert_no', headerName: 'Certificate No', flex: 1 },
  { field: 'date', headerName: 'Date', flex: 1 },
  { field: 'firstName1', headerName: 'Technician', flex: 1 },
  { field: 'lastName1', headerName: 'Action', flex: 1 , renderCell: (params)=>(
    <div>
      <IconButton aria-label="view">
        <VisibilityIcon sx={{color:'green'}}/>
      </IconButton>
    <IconButton>
      <EditIcon sx={{color:'black'}}/>
    </IconButton>
    <IconButton aria-label="print">
        <PrintIcon color='warning' />
      </IconButton>
    <IconButton>
      <DeleteIcon sx={{color:'red'}}/>
    </IconButton>
  </div>
  ) },
  
  
];

const rows = [
  { id: 1, cert_no: 123, firstName: 'Jon',  date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon'},
  { id: 2, cert_no: 123, firstName: 'Cersei', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon'},
  { id: 3, cert_no: 123, firstName: 'Jaime',date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon' },
  { id: 4, cert_no: 123, firstName: 'Arya', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon'},
  { id: 5, cert_no: 123, firstName: 'Daenerys', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon' },
  { id: 6, cert_no:123 , firstName: null, date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon'},
  { id: 7, cert_no: 123, firstName: 'Ferrara', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon'},
  { id: 8, cert_no: 123, firstName: 'Rossini', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon' },
  { id: 9, cert_no: 123, firstName: 'Harvey', date: "01/02/2024", lastName1: 'Snow', firstName1: 'Jon' },
];

function PMI() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div>
    <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>PMI</h1>

    <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
          <div style={{marginRight:"40px"}}>
                <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search>
            </div>
           <div>
                <Link to="/AddPMI"><Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Customer">Create PMI</Button></Link>
           </div>
        
    </div>


    <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
  <DataGrid
    density='compact'
    rows={rows}
    columns={columns}
    initialState={{
      pagination: {
        paginationModel: { page: 0, pageSize: 5 },
      },
    }}
    pageSizeOptions={[5, 10]}
   
  />
</div>



</div>
  )
}

export default PMI;
