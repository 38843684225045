import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Autocomplete, IconButton } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { getAllBills, getBillMastByDate } from '../lib/api-bill';
import { getAllCustomersAuto } from '../lib/api-customer';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import LoadingSpinner from '../../../frontend/src/components/common/LoadingSpinner';
const StyledInputBase = styled(TextField)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

function Report() {
    const [rows, setRows] = useState([]);
    const [cust, setCust] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [custName, setCustName] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

   
        const fetchData1 = async () => {
            try {
              setLoading(true);
                const response = await getAllBills();
                const jsonData = response.data.map((row, index) => ({
                    id: row.Id,
                    srNo: index + 1,
                    ...row,
                }));
                setRows(jsonData);
            } catch (error) {
                console.error('Error fetching bills:', error);
            }finally{
              setLoading(false);
            }
        };
        useEffect(() => {
        fetchData1();
    }, []);

    
        const fetchData = async () => {
            try {
              setLoading(true);
                const response = await getAllCustomersAuto();
                const customerOptions = response.map((row) => ({ value: row.Cust_Id, label: row.Cust_Name }));
                setCust(customerOptions);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }finally{
              setLoading(false);
            }
        };
        useEffect(() => {
        fetchData();
    }, []);

    // const handleApplyFilter = async () => {
    //     try {
    //         const formattedStartDate = startDate.format('DD/MM/YYYY');
    //         const formattedEndDate = endDate.format('DD/MM/YYYY');

    //         const response = await getBillMastByDate({ fromDate: formattedStartDate, toDate: formattedEndDate, cName: custName });
    //         const searchedData = response.data.map((row, index) => ({
    //             id: row.Id,
    //             srNo: index + 1,
    //             ...row
    //         }));
    //         setRows(searchedData);
    //     } catch (error) {
    //         console.error('Error fetching filtered data:', error);
    //         setError('Error fetching filtered data');
    //     }
    // };
    const handleApplyFilter = async () => {
      try {
          const formattedStartDate = startDate.format('DD/MM/YYYY');
          const formattedEndDate = endDate.format('DD/MM/YYYY');
  
          const filterCriteria = { fromDate: formattedStartDate, toDate: formattedEndDate };
          if (custName) {
              filterCriteria.cName = custName;
          }
  
          const response = await getBillMastByDate(filterCriteria);
          const searchedData = response.data.map((row, index) => ({
              id: row.Id,
              srNo: index + 1,
              ...row
          }));
          setRows(searchedData);
      } catch (error) {
          console.error('Error fetching filtered data:', error);
          setError('Error fetching filtered data');
      }
  };
  

    const columns = [
        { field: 'srNo', headerName: 'Sr. No.', flex: 1 },
        { field: 'CustomerName', headerName: 'Customer Name', flex: 1 },
        { field: 'Cust_Address', headerName: 'Customer Address', flex: 1 },
        { field: 'Bill_Date', headerName: 'Invoice Date', flex: 1 },
        { field: 'Bill_no', headerName: 'Bill No', flex: 1 },
        { field: 'Test_Type', headerName: 'Test Type', flex: 1 },
        { field: 'GrandTotalAmt', headerName: 'Amount', flex: 1 },
    ];


  return (
    <div>
    {loading && <LoadingSpinner/>}
      <h1 style={{ fontFamily: 'Overpass, serif', textAlign: 'center', fontSize: '2rem' }}>Reports</h1>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginInline: '5%', marginBlock: '4%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      
           <div>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={startDate}
              onChange={date => setStartDate(date)}
              renderInput={(params) => <TextField {...params} />}
              format="DD/MM/YYYY"
              label="Start Date"
            />
          </LocalizationProvider>
        </FormControl>
      </div>
      <div>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              onChange={date => setEndDate(date)}
              renderInput={(params) => <TextField {...params} />}
              format="DD/MM/YYYY"
              label="End Date"
            />
          </LocalizationProvider>
        </FormControl>
      </div>
          </div>
          <div style={{display:"flex",justifyContent:"space-evenly", marginLeft:"14%"}}>
          <Autocomplete
           options={cust}
            value={custName}
            onChange={(event, newValue) => setCustName(newValue)}
            
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Customer Name"
            sx={{width:"47vw"}} />}
          />
          <IconButton aria-label='export'>
            <Button color='error' variant="contained" onClick={handleApplyFilter}>Search</Button>
          </IconButton>
          </div>
        
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CSVLink
          data={rows.map((row) => columns.map((col) => row[col.field]))}
          headers={columns.map((column) => column.headerName)}
          separator=","
          filename="visit_report.csv"
        >
            <Button color="success" variant="contained" endIcon={<SaveAltIcon />}>Export to CSV</Button>
        </CSVLink>
        </div>
      </div>

      <div style={{ paddingTop: '20px', height: 400, width: '100%' }}>
        <DataGrid
          density='compact'
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
    </div>
  );
}

export default Report;
