import React from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import TableContainer from '@mui/material/TableContainer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';

import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import DeleteIcon from '@mui/icons-material/Delete';
import {Autocomplete, IconButton} from '@mui/material';
import { red } from '@mui/material/colors';

import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';

function createData(
  id,
  desc,
  qty,
  weight,
  ok_qty,
  rejec
) {
  return {id,desc,qty,weight,ok_qty,rejec};
}

const rows2 = [
  createData(1,'Test Description',13,17,'--','N',0),


];


function createData2(
    id,
    ele,
    samp,
    minP,
    maxP,
  ) {
    return {id,ele,samp,minP,maxP};
  }
  
  const rows3 = [
    createData2(1,'ele','samp','minP','maxP'),
  
  
  ];
function CreatePMI() {
  const[cust,setCust]=React.useState([]);
  React.useEffect(() => {
      const customer_=['x','y','z'];
      setCust(customer_);
    }, []);
  const navigate = useNavigate();
  const handleBack =()=>
  {
    navigate('/pages/Transaction/PMI')
  }
  return (
    <div>
      <h2>Add PMI</h2>
      <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
              <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
              <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}> <DatePicker name="Date" format="DD/MM/YYYY" label="Date"/> </LocalizationProvider></FormControl> 
                  <TextField style={{width:"37.5vw"}} id="outlined-basic" label="Certificate No." variant="outlined" /> 
              </div>
              <div style={{display:"grid",gridTemplateColumns:"1fr 1fr",rowGap:"15%",columnGap:"1rem",marginBottom:"2%"}}>
            <Autocomplete options={cust} renderInput={(params)=>(<TextField {...params}    id='outlined-basic' label='Customer' variant='outlined'/>)}/>
            <TextField    id="outlined-basic" label="Reference" variant="outlined" />
            <TextField    id="outlined-basic" label="Specified" variant="outlined" />
            <TextField    id="outlined-basic" label="Sample Desc" variant="outlined" />
        </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField   id="outlined-basic" label="Part Description" variant="outlined" style={{ marginRight: '8px' }} />
      <TextField   id="outlined-basic" label="Quantity" variant="outlined" style={{ marginRight: '8px' }} />
      <TextField   id="outlined-basic" label="Weight" variant="outlined" style={{ marginRight: '8px' }} />
      <TextField   id="outlined-basic" label="Accept" variant="outlined" style={{ marginRight: '8px' }} />
      <TextField   id="outlined-basic" label="Reject" variant="outlined" style={{ marginRight: '8px' }} />
      <TextField   id="outlined-basic" label="Remarks" variant="outlined" style={{ marginRight: '8px' }} />
      <Button style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>
              </div>
        </div>
        <TableContainer style={{marginTop:"50px"}} component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor:"#1976d2" }}>
                            <TableRow>
                                <TableCell style={{color:"#ffffff"}}>Sr. No.</TableCell>
                                <TableCell style={{color:"#ffffff"}}> Part Description</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Qty</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Weight</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Ok Qty</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Reject</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Action</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows2.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell>{row.desc}</TableCell>
                                <TableCell>{row.qty}</TableCell>
                                <TableCell>{row.weight}</TableCell>
                                <TableCell>{row.ok_qty}</TableCell>
                                <TableCell>{row.rejec}</TableCell>
                                <TableCell><IconButton aria-label='delete'style={{ color: red[500] }}><DeleteIcon/> </IconButton></TableCell> 
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
               </TableContainer>


              <div style={{display:"flex" , marginTop:"25px", justifyContent:"space-evenly",gap:"5%", marginBottom:"50px"}}>


              <TableContainer style={{minWidth:"28vw"}} component={Paper}>
                    <Table sx={{ minWidth:400 }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor:"#1976d2" }}>
                            <TableRow>
                                <TableCell style={{color:"#ffffff"}}>Element</TableCell>
                                <TableCell style={{color:"#ffffff"}}>Sample</TableCell>
                                <TableCell style={{color:"#ffffff"}}>MinP</TableCell>
                                <TableCell style={{color:"#ffffff"}}>MaxP</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows3.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="th" scope="row">
                                    {row.ele}
                                </TableCell>
                                <TableCell>{row.samp}</TableCell>
                                <TableCell>{row.minP}</TableCell>
                                <TableCell>{row.maxP}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
               </TableContainer>

               <div style={{display:"flex" , flexDirection:"column",gap:"1vh" ,minWidth:"10vw"}}>
               <Button color='success' variant='contained' style={{textTransform:"none"}}>Add Sample</Button>
               <Button color='warning' variant='contained' style={{textTransform:"none"}}>Update Sample</Button>
               <Button color='primary' variant='contained' style={{textTransform:"none"}}>Reset</Button>
               </div>

               <TableContainer style={{minWidth:"28vw"}} component={Paper}>
                    <Table sx={{ minWidth:400 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                               
                                <TableCell align='center' style={{borderBottom:"2px solid black"}}>Samples</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows2.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell align='center' component="th" scope="row">
                                    ----
                                </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
               </TableContainer>



              </div>
             



               <div style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr", gap:"2rem" , marginBottom:"7vh"}}>
                      
                        <TextField   id="outlined-basic" label="Location" variant="outlined" />
                        <TextField   id="outlined-basic" label="Mode" variant="outlined" />
                        <TextField   id="outlined-basic" label="Remarks" variant="outlined" />
                      
                      
                        <TextField   id="outlined-basic" label="STD" variant="outlined" />
                        <TextField   id="outlined-basic" label="Instrument" variant="outlined" />
                        <TextField   id="outlined-basic" label="Tested By" variant="outlined" />
                   
                        <TextField   id="outlined-basic" label="Calibration Certificate" variant="outlined" />
                        
                    <div style={{display:"flex",gap:"3rem"}}>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Print" />
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Send Mail" />
                    </div>
    

               </div>

               <div style={{display:"flex",justifyContent:"flex-end",gap:"2%"}}>
                        <Button onClick={handleBack} style={{backgroundColor:"red",color:"white"}} variant="Add Material">CANCEL</Button>
                        <Button style={{backgroundColor:"Green",color:"white"}} variant="Add Material">SAVE</Button>
                </div>
    </div>
  )
}

export default CreatePMI;
