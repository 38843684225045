import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateDye from "./CreateDye";
  
  const AddDye = () => {
    const { dyeId } = useParams();
    const isEdit = dyeId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{dyeId === undefined ? "Add Dye" : "Edit Dye"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateDye dyeId={dyeId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddDye;
  