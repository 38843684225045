import axios from 'axios';
import { CUSTOMER_BASE_PATH } from './api-base-paths';

export const getAllCustomer = async () => {
    try {
        const response = await axios.get(`${CUSTOMER_BASE_PATH}/getAllCustomers`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};

export const getAllCustomersAuto = async () => {
    try {
        const response = await axios.get(`${CUSTOMER_BASE_PATH}/getAllCustomersAuto`);
        console.log("hiiii",response);
        console.log("byyyyyy",response.data);
        return response.data;
        

    } catch (error) {
        console.error('Error fetching all customer:', error);
        throw error;
    }
};


export const createCustomer = async (customerData) => {
    try {
        const response = await axios.post(`${CUSTOMER_BASE_PATH}/createCustomer`, customerData);
        return response.data;
    } catch (error) {
        console.error('Error creating customer:', error);
        throw error;
    }
};

export const deleteCustomer = async (customerId) => {
    try {
        const response = await axios.delete(`${CUSTOMER_BASE_PATH}/deleteCustomer/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting customer:', error);
        throw error;
    }
};

export const updateCustomer = async (customerId, customerData) => {
    try {
        const response = await axios.put(`${CUSTOMER_BASE_PATH}/updateCustomer/${customerId}`, customerData);
        return response.data;
    } catch (error) {
        console.error('Error updating customer:', error);
        throw error;
    }
};

export const getCustomerById = async (customerId) => {
    try {
        const response = await axios.get(`${CUSTOMER_BASE_PATH}/getCustomerById/${customerId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching customer by ID:', error);
        throw error;
    }
};
