import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateSpark from "./CreateSpark";
  
  const AddSpark = () => {
    const { sparkId } = useParams();
    const isEdit = sparkId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{sparkId === undefined ? "Add Spark" : "Edit Spark"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateSpark sparkId={sparkId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddSpark;
  