import axios from 'axios';
import { ELEMENT_BASE_PATH } from './api-base-paths';

export const getAllElements = async () => {
    try {
        const response = await axios.get(`${ELEMENT_BASE_PATH}/getAllElements`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all element:', error);
        throw error;
    }
};

export const getAllSpgrade = async () => {
    try {
        const response = await axios.get(`${ELEMENT_BASE_PATH}/getAllSpgrade`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all grade:', error);
        throw error;
    }
};

export const createElement = async (elementData) => {
    try {
        const response = await axios.post(`${ELEMENT_BASE_PATH}/createElement`, elementData);
        return response.data;
    } catch (error) {
        console.error('Error creating element:', error);
        throw error;
    }
};

export const createSpgrade = async (spgradeData) => {
    try {
        const response = await axios.post(`${ELEMENT_BASE_PATH}/createSpgrade`, spgradeData);
        return response.data;
    } catch (error) {
        console.error('Error creating grade:', error);
        throw error;
    }
};

export const deleteElement = async (elementId) => {
    try {
        const response = await axios.delete(`${ELEMENT_BASE_PATH}/deleteElement/${elementId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting element:', error);
        throw error;
    }
};

export const updateElement = async (elementId, elementData) => {
    try {
        const response = await axios.put(`${ELEMENT_BASE_PATH}/updateElement/${elementId}`, elementData);
        return response.data;
    } catch (error) {
        console.error('Error updating element:', error);
        throw error;
    }
};

export const getElementById = async (elementId) => {
    try {
        const response = await axios.get(`${ELEMENT_BASE_PATH}/getElementById/${elementId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching element by ID:', error);
        throw error;
    }
};
