import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateUltrasonic from "./CreateUltrasonic";
  
  const AddUltrasonic = () => {
    const { ultraId } = useParams();
    const isEdit = ultraId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{ultraId === undefined ? "Add Ultrasonic" : "Edit Ultrasonic"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateUltrasonic ultraId={ultraId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddUltrasonic;
  