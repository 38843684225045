import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  getAllMaterials,
  createMaterial,
  deleteMaterial, 
  updateMaterial,
  getMaterialById,
} from '../../lib/api-material';
import LoadingSpinner from '../../components/common/LoadingSpinner';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  height: 'flex',
  borderRadius: '2%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const Material = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [materials, setMaterials] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [materialName, setMaterialName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [mID, setMID] = useState(null)
  const handleOpen = () => setOpen(true);
  const [loading, setLoading] = useState(false);

  const handleEdit = () => setOpen1(true);
  const handleEditClose = () => {
    setOpen1(false);
    setMaterialName(''); 
    setErrorMessage('');
  };
  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (materialId) =>
  {
    setOpen2(true);
    setMID(materialId);

  }
  
  const handleClose = () => {
    setOpen(false);
    setMaterialName(''); 
    setErrorMessage('');
  };
  const handleMaterial = (e) => {
    setMaterialName(e.target.value);
  } 

  
    const fetchMaterials = async () => {
      try {
        setLoading(true)
        const data = await getAllMaterials();
        
        const materialsWithIds = data.map((row, index) => ({
          id: row.Material_Id,
          srNo: index + 1, 
          ...row
        }));
        
        setMaterials(materialsWithIds);
      } catch (error) {
        console.error('Error fetching materials:', error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchMaterials();
  }, []);
  
  

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    
  };

    const handleAddMaterial = async () => {
    if (materialName.trim() === '') {
        setErrorMessage("Material Name cannot be left blank");
    } else {
        setErrorMessage('');
        try {
          setLoading(true)
            await createMaterial({ Material_Nm: materialName });
            fetchMaterials();
            handleClose();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error adding material:', error);
            }
        }finally{
          setLoading(false)
        }
    }
};


  const handleDeleteMaterial = async () => {
  try {
    setLoading(true)
    await deleteMaterial(mID);
    setOpen2(false);
    fetchMaterials();
    setSearchText('');
  } catch (error) {
    console.error('Error deleting material:', error);
  }finally{
          setLoading(false)
        }
};

  
  

  const getMaterial = async (materialId) => {
    handleEdit();
    setMID(materialId);
    try {
      const material = await getMaterialById(materialId);
      const jsonData = material.map((row)=>{
        return row.Material_Nm;
      })
      
      setMaterialName(jsonData);
      
    } catch (error) {
      console.error('Error updating material:', error);
    }
  };
  
  const updateMaterials = async () => {
    if (!materialName || typeof materialName !== 'string' || materialName.trim() === '') {
        setErrorMessage("Material Name cannot be left blank");
    } 
    else {
        setErrorMessage('');
        try {
            const updatedMaterialData = {
                Material_Nm: materialName
            };
            setLoading(true)
            await updateMaterial(mID, updatedMaterialData);
            fetchMaterials();
            handleEditClose();

        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            
            setErrorMessage(error.response.data.error);
        } else {
            console.error('Error adding material:', error);
        }
        }finally{
          setLoading(false)
        }
    }
};

  
  const columns = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'Material_Nm', headerName: 'Material Name', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 0.25,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => getMaterial(params.row.id)}>
            <EditIcon sx={{ color: 'black' }} />
          </IconButton>
          <IconButton onClick={()=> handleOpen2(params.row.id)}>
            <DeleteIcon sx={{ color: 'red' }} />
          </IconButton>
        </div>
      ),
    },
  ];

  

  return (
    <Grid>
      {loading && <LoadingSpinner/>}
       <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Material</h1> 

      
      <Grid style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: '25px', marginRight: '10px' }}>
        <div style={{ marginRight: '10px' }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              value={searchText}
              onChange={handleSearch}
            />
          </Search>
        </div>
        <div>
          <Button onClick={handleOpen} variant="contained" style={{ width: 'flex', backgroundColor: 'red' }}>
            Add Material
          </Button>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div style={{ paddingTop: '20px', height: 400, width: '100%' }}>
          <DataGrid
            density="compact"
            rows={materials.filter((material) =>
              material.Material_Nm && material.Material_Nm.toLowerCase().includes(searchText.toLowerCase())
            )}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
          />
        </div>
      </Grid>

      <Modal
        sx={{ maxWidth: 700, margin: 'auto', marginTop: 2 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                className="customTextField"
                id="outlined-basic"
                label="Material Name"
                size="small"
                variant="outlined"
                value={materialName}
                onChange={handleMaterial}
                error={errorMessage !== ''}
                helperText={errorMessage}
                fullWidth
              />
            </Grid>


            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Button
                variant="contained"
                color="success"
                onClick={handleAddMaterial}

                fullWidth
              >
                Save
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Button variant="contained" color="error" onClick={handleClose} fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
        sx={{ maxWidth: 700, margin: 'auto', marginTop: 2 }}
        open={open1}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                className="customTextField"
                id="outlined-basic"
                label="Material Name"
                size="small"
                variant="outlined"
                fullWidth
                value={materialName}
                onChange={handleMaterial}
                error={errorMessage !== ''}
                helperText={errorMessage}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <Button
                variant="contained"
                color="success"
                onClick={updateMaterials}
                fullWidth
              >
                Update
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Button variant="contained" color="error" onClick={handleEditClose} fullWidth>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>


      <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteMaterial}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
    </Grid>
  );
};

export default Material;
