import React, { useEffect, useState } from 'react'

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {IconButton, Typography} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Autocomplete from '@mui/material/Autocomplete';
import Modal from '@mui/material/Modal';
import {
  Box,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { createCustomer, deleteCustomer, getAllCustomer, getCustomerById, updateCustomer } from '../../lib/api-customer';
import LoadingSpinner from '../../components/common/LoadingSpinner';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', 
  maxWidth: '50vw', 
  height: 'auto', 
  borderRadius: '2%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,

};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  height: 'flex',
  borderRadius: '2%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: '100%',
    
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));




const Customer = () => {
  const[indianStates,setIndianStates]= useState([]);
  const[customers, setCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [cust_Address, setCust_Address] = useState('');
  const [gst_No, setGST_NO] = useState('');
  const [pan_no, setPan_no] = useState('');
  const [state, setState] = useState('');
  const [vendor_Code, setVendor_Code] = useState('');
  const [mobile_No, setMobile_No] = useState('');
  const handleOpen = () => setOpen(true);
  const handleEdit = () => setOpen1(true);
  const [mID, setMID] = useState(null);
  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const [loading, setLoading] = useState(false);

  const [errorMessageName, setErrorMessageName] = useState('');
  const [errorMessageMobile, setErrorMessageMobile] = useState('');
  const [errorMessageGST, setErrorMessageGST] = useState('');
  const [errorMessagePAN, setErrorMessagePAN] = useState('');
  const [errorMessageVendorCode, setErrorMessageVendorCode] = useState('');
  const [errorMessageAddress, setErrorMessageAddress] = useState('');
  const [errorMessageState, setErrorMessageState] = useState('');
  const handleEditClose = () => {
    setOpen1(false);
    setCustomerName('');
    setCust_Address('');
    setGST_NO('');
    setPan_no('');
    setState('');
    setVendor_Code('');
    setMobile_No('');
    setErrorMessageAddress('');
    setErrorMessageGST('');
    setErrorMessageMobile('');
    setErrorMessageState('');
    setErrorMessageVendorCode('');
    setErrorMessagePAN('');
    setErrorMessageName('');
  };

  const handleClose = () => {
    setOpen(false);
    setCustomerName('');
    setCust_Address('');
    setGST_NO('');
    setPan_no('');
    setState('');
    setVendor_Code('');
    setMobile_No(''); 
    setErrorMessageAddress('');
    setErrorMessageGST('');
    setErrorMessageMobile('');
    setErrorMessageState('');
    setErrorMessageVendorCode('');
    setErrorMessagePAN('');
    setErrorMessageName('');
  };

  const handleOpen2 = (customerId) =>
  {
    setOpen2(true);
    setMID(customerId);

  }

  useEffect(() => {
    const  fetchStates =[
      'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli and Daman and Diu',
        'Delhi',
        'Ladakh',
        'Lakshadweep',
        'Puducherry',
    ];
    setIndianStates(fetchStates);
  }, []);



  
    const fetchCustomers = async () =>{
      try {
        setLoading(true)
        const data = await getAllCustomer();
        
        
        const customersWithIds = data.map((row, index) => ({
          id: row.Cust_Id,
          SrNo: index + 1, 
          ...row
        }));
        setCustomers(customersWithIds);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }finally{
        setLoading(false)
      }
    };
    useEffect(()=>{
    fetchCustomers();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleAddCustomer = async () =>{
    let isValid = true;

    if (customerName.trim() === '') {
      setErrorMessageName('Customer name cannot be empty');
      isValid = false;
    } else {
      setErrorMessageName('');
    }
  
    if (mobile_No.trim() === '') {
      setErrorMessageMobile('Mobile number cannot be empty');
      isValid = false;
    } 
    else if (mobile_No.length != 10)
     {
         setErrorMessageMobile("Mobile Number must be of 10 digits");
         isValid=false;
     } 
    else {
      setErrorMessageMobile('');
    }
  
    if (gst_No.trim() === '') {
      setErrorMessageGST('GST number cannot be empty');
      isValid = false;
    }
    else if (gst_No.length !== 15)
     {
         setErrorMessageGST("GST Number must be of 15 digits");
         isValid=false;
     } 
     else if (customers.find(customer => customer.GST_NO === gst_No)){
      setErrorMessageGST('GST number Already Exist');
      isValid= false;
     }
     else {
      setErrorMessageGST('');
    }
  
    if (pan_no.trim() === '') {
      setErrorMessagePAN('PAN number cannot be empty');
      isValid = false;
    }
     else if (pan_no.length !== 10)
     {
         setErrorMessagePAN("PAN Number must be of 10 digits");
         isValid=false;
     } 
     else {
      setErrorMessagePAN('');
    }
  
     if (cust_Address.trim() === '') {
      setErrorMessageAddress('Address cannot be empty');
      isValid = false;
    } else {
      setErrorMessageAddress('');
    }

    if (state.trim() === '') {
      setErrorMessageState('State cannot be empty');
      isValid = false;
    } else {
      setErrorMessageState('');
    }
    if(isValid)
    {
      try {
        setLoading(true)
        await createCustomer({ 
          Cust_Name: customerName,
          Cust_Address:cust_Address,
          GST_NO:gst_No,
          Pan_no:pan_no,
          
          State:state,
          
          Vendor_Code:vendor_Code,
          Mobile_No:mobile_No,
        });
        fetchCustomers();
         handleClose();
        
       } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            
          setErrorMessageName(error.response.data.error);
      } else {
          console.error('Error adding material:', error);
      }
       }finally{
        setLoading(false)
      }
    }
  };

  const handleDeleteCustomer = async () => {
    try {
      setLoading(true)
      await deleteCustomer(mID);
      setOpen2(false);
      fetchCustomers();
      setSearchText('');
    } catch (error) {
      console.error('Error deleting customer:', error);
    }finally{
      setLoading(false)
    }
  };

  const getCustomer = async (customerId) => {
    handleEdit();
    setMID(customerId);
    try {
      const customer = await getCustomerById(customerId);
      
      const jsonData = customer.map((row)=>{
        setCustomerName(row.Cust_Name);
        setCust_Address(row.Cust_Address);
        setGST_NO(row.GST_NO);
        setPan_no(row.Pan_no);
        setState(row.State);
        setVendor_Code(row.Vendor_Code);
        setMobile_No(row.Mobile_No);
      })
     
      
    } catch (error) {
      console.error('Error updating customer:', error);
    }
  };

  const updateCustomers = async () => {
    let isValid = true;

    if (customerName.trim() === '') {
      setErrorMessageName('Customer name cannot be empty');
      isValid = false;
    } else {
      setErrorMessageName('');
    }
  
    if (mobile_No.trim() === '') {
      setErrorMessageMobile('Mobile number cannot be empty');
      isValid = false;
    } 
    else if (mobile_No.length != 10)
     {
         setErrorMessageMobile("Mobile Number must be of 10 digits");
         isValid=false;
     } 
    else {
      setErrorMessageMobile('');
    }
  
    if (gst_No.trim() === '') {
      setErrorMessageGST('GST number cannot be empty');
      isValid = false;
    }
    else if (gst_No.length !== 15)
     {
         setErrorMessageGST("GST Number must be of 15 digits");
         isValid=false;
     } 
     
     else {
      setErrorMessageGST('');
    }
  
    if (pan_no.trim() === '') {
      setErrorMessagePAN('PAN number cannot be empty');
      isValid = false;
    }
     else if (pan_no.length !== 10)
     {
         setErrorMessagePAN("PAN Number must be of 10 digits");
         isValid=false;
     } 
     else {
      setErrorMessagePAN('');
    }
  
  
  
    if (cust_Address.trim() === '') {
      setErrorMessageAddress('Address cannot be empty');
      isValid = false;
    } else {
      setErrorMessageAddress('');
    }

    if (state.trim() === '') {
      setErrorMessageState('State cannot be empty');
      isValid = false;
    } else {
      setErrorMessageState('');
    }
    if(isValid){
    try {
      const updatedCustomerData = {
        Cust_Name: customerName,
        Cust_Address:cust_Address,
        GST_NO:gst_No,
        Pan_no:pan_no,
        State:state,
        Vendor_Code:vendor_Code,
        Mobile_No:mobile_No,
      };
      setLoading(true)
      await updateCustomer(mID, updatedCustomerData);
      fetchCustomers();
      handleEditClose();
      setSearchText('');
    } catch (error) {
      console.error('Error updating customer:', error);
    }finally{
      setLoading(false)
    }
  }
  };

  const columns = [
    { field: 'SrNo', headerName: 'Sr.No', flex: 1 },
    { field: 'Cust_Name', headerName: 'customerName', flex: 1 },
    { field: 'Pan_no', headerName: 'PAN_No', flex: 1 },
    { field: 'Vendor_Code', headerName: 'Vendor Code', flex: 1 },
    { field: 'Cust_Address', headerName: 'Address', flex: 1 },
    { field: 'Mobile_No', headerName: 'Mobile No.', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 1, renderCell: (params)=>(
      <div>
      <IconButton onClick={() => getCustomer(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) },
    
  ];

  function filterCustomers(customer, searchText) {
    return (
      (customer.Cust_Name && customer.Cust_Name.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.Cust_Address && customer.Cust_Address.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.GST_NO && customer.GST_NO.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.Pan_no && customer.Pan_no.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.State && customer.State.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.Vendor_Code && customer.Vendor_Code.toLowerCase().includes(searchText.toLowerCase())) ||
      (customer.Mobile_No && customer.Mobile_No.toLowerCase().includes(searchText.toLowerCase()))
    );
  }


  return (
    <Grid>
      {loading && <LoadingSpinner/>}
        <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Customer</h1>  

        <Grid  style={{ width: '100%', marginTop: '25px',marginRight:"10px", display:"flex",justifyContent:"end"}}>
             <div style={{marginRight:"20px"}}>
                    <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </Search>
                </div>
            <div>
            <Button  
            onClick={handleOpen}  
            fullWidth variant="contained" 
            style={{width:"flex",backgroundColor:"red"}}
            >
              Add Customer
            </Button>
            </div>
          </Grid>

          <Grid item xs={12}>
        <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
      <DataGrid
        density='compact'
        rows={customers.filter((customer) => filterCustomers(customer, searchText))}
        
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
       
      />
    </div>
   </Grid>


    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer Name"
            size="small"
            variant="outlined"
            value={customerName}
            onChange={(e)=> setCustomerName(e.target.value)}
            error={errorMessageName !== ''}
            helperText={errorMessageName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Mobile Number"
            size="small"
            variant="outlined"
            value={mobile_No}
            onChange={(e)=> setMobile_No(e.target.value)}
            error={errorMessageMobile !==''}
            helperText={errorMessageMobile}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer GST Number"
            size="small"
            variant="outlined"
            value={gst_No}
            onChange={(e)=> setGST_NO((e.target.value).trim().toUpperCase())}
            error={errorMessageGST !==''}
            helperText={errorMessageGST}
            fullWidth
            
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label=" PAN"
            size="small"
            variant="outlined"
            value={pan_no}
            onChange={(e)=> setPan_no(e.target.value)}
            error={errorMessagePAN !==''}
            helperText={errorMessagePAN}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
              className="customTextField"
              id="outlined-basic"
              label="Vendor Code "
              size="small"
              variant="outlined"
              value={vendor_Code}
            onChange={(e)=> setVendor_Code(e.target.value)}
            error={errorMessageVendorCode !== ''}
            helperText={errorMessageVendorCode}
              fullWidth
            />
          
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer Address"
            size="small"
            variant="outlined"
            value={cust_Address}
            onChange={(e)=> setCust_Address(e.target.value)}
            error={errorMessageAddress !== ''}
            helperText={errorMessageAddress}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
        <Autocomplete
      options={indianStates}
      renderInput={(params) => (
        <TextField
          {...params}
          className="customTextField"
          id="outlined-basic"
          size="small"
          label="Select or type state"
          variant="outlined"
          value={state}
          error={errorMessageAddress !==''}
          helperText={errorMessageState}
          
        />
      )}
      onChange={(event, value) => {
        setState(value);
      }}
    />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button 
          variant="contained" 
          color="success" 
          onClick={handleAddCustomer}
          fullWidth
          >
              Save
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>
    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleEditClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer Name"
            size="small"
            variant="outlined"
            value={customerName}
            onChange={(e)=> setCustomerName(e.target.value)}
            error={errorMessageName !== ''}
            helperText={errorMessageName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Mobile Number"
            size="small"
            variant="outlined"
            value={mobile_No}
            onChange={(e)=> setMobile_No(e.target.value)}
            error={errorMessageMobile !==''}
            helperText={errorMessageMobile}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer GST Number"
            size="small"
            variant="outlined"
            value={gst_No}
            onChange={(e)=> setGST_NO(e.target.value)}
            error={errorMessageGST !==''}
            helperText={errorMessageGST}
            fullWidth
            
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label=" PAN"
            size="small"
            variant="outlined"
            value={pan_no}
            onChange={(e)=> setPan_no(e.target.value)}
            error={errorMessagePAN !==''}
            helperText={errorMessagePAN}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
              className="customTextField"
              id="outlined-basic"
              label="Vendor Code "
              size="small"
              variant="outlined"
              value={vendor_Code}
            onChange={(e)=> setVendor_Code(e.target.value)}
            error={errorMessageVendorCode !== ''}
            helperText={errorMessageVendorCode}
              fullWidth
            />
          
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Customer Address"
            size="small"
            variant="outlined"
            value={cust_Address}
            onChange={(e)=> setCust_Address(e.target.value)}
            error={errorMessageAddress!==''}
            helperText={errorMessageAddress}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
        <Autocomplete
      options={indianStates}
      value={state}
      renderInput={(params) => (
        <TextField
          {...params}
          className="customTextField"
          id="outlined-basic"
          size="small"
          label="Select or type state"
          variant="outlined"
          error={errorMessageState!==''}
          helperText={errorMessageState}
          
        />
      )}
      onChange={(event, value) => {
        setState(value);
      }}
    />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button 
          variant="contained" 
          color="success" 
          onClick={updateCustomers}
          fullWidth
          >
              Update
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="error"
            onClick={handleEditClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>
      

    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style1}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteCustomer}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
    </Grid>
  )
}

export default Customer;




