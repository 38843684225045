import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false
};

const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase('login', (state) => {
      state.isAuthenticated = true;
    })
    .addCase('logout', (state) => {
      state.isAuthenticated = false;
    });
});

export { rootReducer }; // Export rootReducer as a named export
