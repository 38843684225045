import React, { useState ,useEffect } from 'react'
import * as Yup from 'yup';
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Autocomplete, IconButton, Chip, Checkbox, FormControlLabel} from '@mui/material';
import {useFormik} from "formik";
import { Select, MenuItem, InputLabel , FormHelperText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import { getEddyById,updateEddy,createEddy, getAutoCertificateNo, deleteEddyDescription, getPrint, getEddyPDF} from "../../../lib/api-eddy";
import { getAllCustomersAuto } from "../../../lib/api-customer";
import { getAllMaterials } from '../../../lib/api-material';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from "../../../components/common/CustomSnackbar";




const CreateEddys = ({eddyId,isEdit}) => {
    
    const[cust,setCust]=useState([]);
    const[mate,setMate]=useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [latestId,setLatestId]=useState(false)
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const [ pdfData, setPdfData] = useState(null);
    const handlePDFModalClose = ()=>{
      setPdfData(null)
      setOpenPDFModal(false)};

      const navigate=useNavigate();
      const handleBack=() =>
      {
        navigate('/Eddy')
      }

      
  
      
      const [rows, setRows] = useState([]);
  const [formikValues, setFormikValues] = useState({
    Description: '',
    Qty: '',
    Weight: '',
    Accepted: '',
    
    Rejected: '',
    Remarks: '',
    
  });
  const [idCounter, setIdCounter] = useState(1);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormikValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddRow = () => {
    if (!formikValues.Description || !formikValues.Qty || !formikValues.Weight || !formikValues.Accepted || !formikValues.Rejected || !formikValues.Remarks) {
        alert("Please fill in all required fields.");
        return; 
    }

    const newRow = { id: idCounter, srNo: idCounter, ...formikValues };
    setIdCounter(idCounter + 1);

    if (rows.length > 0) {
        const maxSrNo = Math.max(...rows.map(row => row.srNo));
        newRow.srNo = maxSrNo + 1;
    } else {
        newRow.srNo = 1;
    }

    newRow.id = newRow.srNo;

    setRows(prevRows => [...prevRows, newRow]);

    setFormikValues({
        Description: '',
        Qty: '',
        Weight: '',
        Accepted: '',
        Rejected: '',
        Remarks: '',
    });
};




const handleDelete1 = (id) => {
  deleteEddyDescription(id)
      .then(response => {
          console.log(response.data); 
          setRows(prevRows => prevRows.filter(row => row.id !== id));
      })
      .catch(error => {
          console.error('Error deleting row:', error); // Handle error
      });
};


      const initalValues = {
        
        Certificate_No:'',
        Cert_Date:new Date(),
        Referance_Id:'',
        Frequency:'',
        Eddy_Sonic:'',
        Scope_Exam:'After HT',
        Gain:'',
        Threshold:'',
        Sta_Dyn:'',
        Location:'',
        Cust_Name:{value:'',label:''},
        Witness_By:'',
        Phase:'',
        Filter_hp:'',
        Filter_lp:'',
        technician:'',
        Designation:'ASNT LEVEL II - UT.MT.PT',
        Material_Nm:'',
      }

     


      useEffect(()=>{
        const fetchData = async () => {
          try {
            
            const response = await getAllCustomersAuto();
            setCust(() => {
              const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name } });
              return customerOp; 
          });
          } catch (error) {
            console.error("Error fetching customers:", error);
          }
        };
        fetchData();
      },[])

      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllMaterials();
            const materialOp = response.map((row) => { return { value: row.Material_Id, label: row.Material_Nm } });
            setMate(materialOp);
          } catch (error) {
            console.error("Error fetching materials:", error);
          }
        };
        fetchData();
      },[])
      
      useEffect(() => {
        const fetchCertificateNo = async () => {
          try {
            const getCertificateNo = await getAutoCertificateNo();
            const maxNumber = getCertificateNo.data.nextCertificateNo;
            formik.setFieldValue('Certificate_No' , maxNumber) ;

          } catch (error) {
            
          }
        }
        fetchCertificateNo();
      },[])

      useEffect(() => {
        const fetchData = async () =>
        {
            try {
              setLoading(true);
              const getEddyData = await getEddyById(eddyId);
              setLatestId(true)
              const eddyData = getEddyData.data.eddy;

              const parseDate = (dateString) => {
                  if (dateString) {
                      const parts = dateString.split('/');
                      return `${parts[2]}-${parts[1]}-${parts[0]}`;
                  }
                  return null;
              };
              const [MaterialValues] = await Promise.all([
                JSON.parse(eddyData.Material_Test),
            ]);

            const selectedOptions = MaterialValues.map(value => ({
                value,
                label: mate.find(option => option.value === value)?.label || ''
            }));
            setSelectedValues(selectedOptions);

            const CustNew = cust.filter(item => item.value == eddyData.Cust_Name)[0];

                const sortedData = {
                  Cert_Date: parseDate(eddyData.Cert_Date) ,
                    Cust_Name:CustNew,
                    Certificate_No: eddyData.Certificate_No,
                    Referance_Id:eddyData.Referance_Id,
                    Material_Nm:selectedOptions,
                    Scope_Exam:eddyData.Scope_Exam,
                    Eddy_Sonic:eddyData.Eddy_Sonic,
                    Frequency:eddyData.Frequency,
                    Phase:eddyData.Phase,
                    Gain:eddyData.Gain,
                    Filter_hp:eddyData.Filter_hp,
                    Filter_lp:eddyData.Filter_lp,
                    Threshold:eddyData.Threshold,
                    Sta_Dyn:eddyData.Sta_Dyn,
                    Witness_By:eddyData.Witness_By,
                    technician:eddyData.technician,
                    Location:eddyData.Location,
                    Designation:eddyData.Designation
                };
                formik.setValues(sortedData);
                
                const sortedRows = getEddyData.data.rows.map((row, index) => ({
                  id: row.id,
                  srNo: index + 1,
                  ...row
              }));
              setRows(sortedRows);
              setLoading(false);
            } catch (error) {
                console.log("Error in Fetching Eddy  Data",error);    
            }finally{
              setLoading(false);
            }
        };

        if(isEdit)
        {
            fetchData();
            
        }
     }, [isEdit,eddyId,cust, mate]);


     const columns = [
      { field: 'srNo', headerName: 'Sr.No.',  flex:1 },
      { field: 'Description', headerName: 'Description',flex: 1 },
      { field: 'Qty', headerName: 'Qty', flex: 1 },
      { field: 'Weight', headerName: 'Weight', flex: 1 },
      { field: 'Accepted', headerName: 'Accepted', flex: 1 },
      { field: 'Rejected', headerName: 'Rejected', flex: 1 },
      { field: 'Remarks', headerName: 'Remarks', flex: 1 },
      { field: 'action', headerName: 'Action', flex: 1,
      renderCell: (params) => (
        <div>
        <IconButton   onClick={()=> handleDelete1(params.row.id) }>
          <DeleteIcon sx={{ color: 'red' }} />
        </IconButton>
      </div>
      ),},
    ];


     const formik = useFormik({
      initialValues:initalValues,
      validationSchema:Yup.object().shape({
        Cert_Date:Yup.date().notRequired(),
        Certificate_No:Yup.number().integer().notRequired(),
          Cust_Name:Yup.object().notRequired(),
          Referance_Id:Yup.string().notRequired(),
          Material_Nm:Yup.array().notRequired(),
          Eddy_Sonic:Yup.string().notRequired(),
          Scope_Exam:Yup.string().notRequired(),
          Frequency:Yup.string().notRequired(),
          Phase:Yup.string().notRequired(),
          Gain:Yup.string().notRequired(),
          Filter_hp:Yup.string().notRequired(),
          Filter_lp:Yup.string().notRequired(),
          Threshold:Yup.string().notRequired(),
          Sta_Dyn:Yup.string().notRequired(), 
          Witness_By:Yup.string().notRequired(),
          technician:Yup.string().notRequired(),
          Location:Yup.string().notRequired(),
          Designation:Yup.string().notRequired()
  
      }),
      onSubmit: async(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          try {
            setLoading(true);
            let response = {};
        
            values.rows = rows;

            if (isEdit) {
              response = await updateEddy(eddyId, values);
              setConfirmationMessage("Eddy Updated successfully");
              setIsConfirmationOpen(true);
              setColor('green')
                
            } else {
              response = await createEddy(values);
              if(response.status==200){
                setConfirmationMessage(response.data);
              setIsConfirmationOpen(true);
              setColor('green')
                setLatestId(true);
                resetForm();
                setSelectedValues([]); 
                setRows([]);
              }
                else{
                  setConfirmationMessage("Somthing Went Wrong!");
              setIsConfirmationOpen(true);
              setColor('error')
                }
            }
          } catch (error) {
            setStatus({ success: false });
            setErrors({ submit: error.response.data.message });
            setSubmitting(false);
          }finally{
            setLoading(false);
          }
        }
        
    });
    const handlep=()=>{
      if(eddyId){
        handlePrintforedit()
      }else{
        handlePrint()
      }
    }

    const handlePrintforedit = async()=>{
      try {
        setLoading(true);
        const getPDF = await getEddyPDF(eddyId);
        setOpenPDFModal(true);
        const filepath=getPDF.data.returnpath
        setPdfData(filepath);
        
      } catch (error) {
        console.error("Error while printing:", error);
      }finally{
        setLoading(false);
    
      }
    }
    const handlePrint= async()=>{
  
      try {
        setLoading(true)
        const response = await getPrint();
        
        setOpenPDFModal(true);
        const filepath=response.returnpath
      
        setPdfData(filepath);
      } catch (error) {
        console.error("Error fetching customers:", error);
      }finally{
        setLoading(false)
      }
  
  }
  
    const handleAutocompleteChange = (event, value) => {
      console.log('selecteddd valuess',value);
      setSelectedValues(value);
      formik.setFieldValue('Material_Nm', value);
    };
    
    const handleDelete = (valueToDelete) => () => {
      setSelectedValues((prevValues) =>
        prevValues.filter((value) => value.label !== valueToDelete.label)
      );
    };

  return (
    
    <div>
      {loading && <LoadingSpinner/>}
       <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
       <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
        <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}>  <DatePicker 
          name="Cert_Date" 
          format="DD/MM/YYYY" 
          label="Date" 
          errors={formik.errors}  
          value={formik.values.Cert_Date ? dayjs(formik.values.Cert_Date) : null}
          // onChange={(date) => {formik.setFieldValue('Cert_Date', date);}}
          onChange={(date) => {
            formik.setFieldValue(
              "Cert_Date",
              date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
            );
          }} /> </LocalizationProvider></FormControl> 
        <TextField style={{width:"37.5vw"}}   id="outlined-basic" label="Certificate No." variant="outlined" name='Certificate_No'  error={formik.errors.Certificate_No && formik.touched.Certificate_No}
                      helperText={formik.touched.Certificate_No ? formik.errors.Certificate_No : ''}
                      onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Certificate_No} />
        </div>
          <div>

          <Autocomplete
          options={cust}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Cust_Name', value || null)}
          value={formik.values.Cust_Name}
          renderInput={(params) => (
            <TextField
            {...params}
            sx={{width:"50vw"}}
            label="Customer Name"
            variant="outlined"
            name="Cust_Name"
            error={formik.errors.Cust_Name && formik.touched.Cust_Name}
            helperText={formik.touched.Cust_Name ? formik.errors.Cust_Name : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />

          </div>
          <div>
              
              <TextField style={{width:"50vw"}}   id="outlined-basic" label="Referance" name='Referance_Id' variant="outlined" error={formik.errors.Referance_Id && formik.touched.Referance_Id}
                      helperText={formik.touched.Referance_Id ? formik.errors.Referance_Id : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Referance_Id} />
          </div>
      
       </div>
   <br/>
   <div >
        <div>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1%' }}>

      
      
        <TextField
          id="outlined-basic"
          label="Part Description"
          name="Description"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Description}
        />

        <TextField
          id="outlined-basic"
          label="Qty"
          name="Qty"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Qty}
        />

        <TextField
          id="outlined-basic"
          label="Weight"
          name="Weight"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Weight}
        />

        <TextField
          id="outlined-basic"
          label="Accepted"
          name="Accepted"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Accepted}
        />

        <TextField
          id="outlined-basic"
          label="Rejected"
          name="Rejected"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Rejected}
        />

        <TextField 
          id="outlined-basic" 
          label="Remarks" 
          name='Remarks' 
          variant="outlined" 
          style={{ width: "10vw" }} 
          onChange={handleChange}
          value={formikValues.Remarks}
        />
        <Button onClick={handleAddRow} style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>
      </div>
      <div style={{ marginBlock: "20px" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
     
    </div>
        </div>

              <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr 1fr", gap:"2rem", marginBlock:"3vh"}}>
      <Autocomplete
      multiple
      options={mate}
      getOptionLabel={(option) => option.label}
      onChange={handleAutocompleteChange}
      value={selectedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          id='outlined-basic'
          label='Material Name'
          name='Material_Nm'
          variant='outlined'
          fullWidth
        />
      )}
      renderTags={(value) =>
        value.map((option, index) => (
          <div key={index} style={{ display: 'flex', overflowX: 'auto', maxWidth: '100%' }}>
            <Chip
              key={index}
              label={option ? option.label : ""}
              variant="outlined"
              onDelete={handleDelete(option)}
              sx={{ margin: '2px' }}
            />
          </div>
        ))
      }
    />

   <FormControl>
     <InputLabel id="Scope_Exam-label">Scope of Examination</InputLabel>
      <Select
        labelId="Scope_Exam-label"
        value={formik.values.Scope_Exam}
        onChange={formik.handleChange}
        label="Scope Examination"
        name="Scope_Exam"
        error={formik.errors.Scope_Exam && formik.touched.Scope_Exam}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="After HT">After HT</MenuItem>
        <MenuItem value="Before HT">Before HT</MenuItem>
        <MenuItem value="Finished">Finished</MenuItem>
        <MenuItem value="Raw">Raw</MenuItem>
        <MenuItem value="Semi Finished">Semi Finished</MenuItem>
      </Select>
      {formik.errors.Scope_Exam && formik.touched.Scope_Exam  && (
    <FormHelperText error>
      {formik.errors.Scope_Exam}
    </FormHelperText>
  )}
      </FormControl>



      <TextField id="outlined-basic" label="Eddy Sonic Test Procedure" name='Eddy_Sonic' variant="outlined"  error={formik.errors.Eddy_Sonic && formik.touched.Eddy_Sonic}
                      helperText={formik.touched.Eddy_Sonic ? formik.errors.Eddy_Sonic : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Eddy_Sonic} />
              
                  <TextField   id="outlined-basic" label="Frequency" name='Frequency' variant="outlined"  error={formik.errors.Frequency && formik.touched.Frequency}
                      helperText={formik.touched.Frequency ? formik.errors.Frequency : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Frequency} />

                  <TextField   id="outlined-basic" label="Phase" name='Phase' variant="outlined"  error={formik.errors.Phase && formik.touched.Phase}
                      helperText={formik.touched.Phase ? formik.errors.Phase : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Phase} />

                  <TextField   id="outlined-basic" label="Gain" name='Gain' variant="outlined" error={formik.errors.Gain && formik.touched.Gain}
                      helperText={formik.touched.Gain ? formik.errors.Gain : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Gain} />

                  <TextField   id="outlined-basic" label="Filter HP" name='Filter_hp' variant="outlined" error={formik.errors.Filter_hp && formik.touched.Filter_hp}
                      helperText={formik.touched.Filter_hp ? formik.errors.Filter_hp : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Filter_hp} />
                     
                  <TextField   id="outlined-basic" label="Filter LP" name='Filter_lp' variant="outlined" error={formik.errors.Filter_lp && formik.touched.Filter_lp}
                      helperText={formik.touched.Filter_lp ? formik.errors.Filter_lp : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Filter_lp}  />
                  <TextField   id="outlined-basic" label="Threshold" name='Threshold' variant="outlined" error={formik.errors.Threshold && formik.touched.Threshold}
                      helperText={formik.touched.Threshold ? formik.errors.Threshold : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Threshold}  />

                  <TextField   id="outlined-basic" label="Static / Dynamic" name='Sta_Dyn' variant="outlined" error={formik.errors.Sta_Dyn && formik.touched.Sta_Dyn}
                      helperText={formik.touched.Sta_Dyn ? formik.errors.Sta_Dyn : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Sta_Dyn} />
                  <TextField   id="outlined-basic" label="Location" name='Location' variant="outlined" error={formik.errors.Location && formik.touched.Location}
                      helperText={formik.touched.Location ? formik.errors.Location : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Location} />
                  <TextField   id="outlined-basic" label="Witness By" name='Witness_By' variant="outlined" error={formik.errors.Witness_By && formik.touched.Witness_By}
                      helperText={formik.touched.Witness_By ? formik.errors.Witness_By : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Witness_By} />

                  <TextField   id="outlined-basic" label="Technician" name='technician' variant="outlined" error={formik.errors.technician && formik.touched.technician}
                      helperText={formik.touched.technician ? formik.errors.technician : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.technician} />

                  <TextField   id="outlined-basic" label="Designation" name='Designation' variant="outlined" 
                        error={formik.errors.Designation && formik.touched.Designation}
                        helperText={formik.touched.Designation ? formik.errors.Designation : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.Designation} />   
              </div>
              <div style={{display:"flex", justifyContent:"flex-end",}}>
                  <Button onClick={handlep} style={{ backgroundColor: latestId ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity: latestId ? 1 : 0.5 }} disabled={!latestId}>PRINT</Button>

                  <Button
                  onClick={formik.handleSubmit}
                  style={{
                  backgroundColor: "Green",
                  color: "white", 
                  marginInline: "0.7rem", 
                  
                  }}
                
                  >
                  {eddyId === undefined ? "Save" : "Update"}
                  </Button>

                  <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
                  <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>
                </div>

                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
       color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </div>
   
  )
}

export default CreateEddys;
