import {
    CardHeader,
    CardContent,
    Typography,
  } from "@mui/material";
  import { useParams } from "react-router-dom";
  import CreateSpectro from "./CreateSpectro";
  
  const AddSpectro = () => {
    const { spectroId } = useParams();
    const isEdit = spectroId === undefined ? false : true;
    return (
      <>
          <CardHeader
            avatar={
              <Typography>
               <h2>{spectroId === undefined ? "Add Spectro" : "Edit Spectro"}</h2>
              </Typography>
            }
          />
          <CardContent>
            <CreateSpectro spectroId={spectroId} isEdit={isEdit}/>
          </CardContent>
  
      </>
    );
  };
  
  export default AddSpectro;
  