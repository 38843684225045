import React from "react";

import Dashboard from "./Dashboard";



const Layout=()=>{
    
    // console.log(condition);
    return(
        <>
           
                 <Dashboard/>
                
        </>
    )
}

export default Layout;