import axios from 'axios';
import { MATERIAL_BASE_PATH } from './api-base-paths';

export const getAllMaterials = async () => {
    try {
        const response = await axios.get(`${MATERIAL_BASE_PATH}/getAllMaterials`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all materials:', error);
        throw error;
    }
};

export const createMaterial = async (materialData) => {
    try {
        const response = await axios.post(`${MATERIAL_BASE_PATH}/createMaterial`, materialData);
        return response.data;
    } catch (error) {
        console.error('Error creating material:', error);
        throw error;
    }
};

export const deleteMaterial = async (materialId) => {
    
    try {
        const response = await axios.delete(`${MATERIAL_BASE_PATH}/deleteMaterial/${materialId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting material:', error);
        throw error;
    }
};

export const updateMaterial = async (materialId, materialData) => {
    
    try {
        const response = await axios.put(`${MATERIAL_BASE_PATH}/updateMaterial/${materialId}`, materialData);
        return response.data;
    } catch (error) {
        console.error('Error updating material:', error);
        throw error;
    }
};

export const getMaterialById = async (materialId) => {
    try {
        const response = await axios.get(`${MATERIAL_BASE_PATH}/getMaterialById/${materialId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching material by ID:', error);
        throw error;
    }
};
