import axios from 'axios';
import { UT_BASE_PATH } from './api-base-paths';

export const getAllEquipment = async () => {
    try {
        const response = await axios.get(`${UT_BASE_PATH}/getAllEquipment`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all equipment:', error);
        throw error;
    }
};

export const createEquipment = async (equipmentData) => {
    try {
        const response = await axios.post(`${UT_BASE_PATH}/createEquipment`, equipmentData);
        return response.data;
    } catch (error) {
        console.error('Error creating equipment:', error);
        throw error;
    }
};

export const deleteEquipment = async (equipmentId) => {
    try {
        const response = await axios.delete(`${UT_BASE_PATH}/deleteEquipment/${equipmentId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting equipment:', error);
        throw error;
    }
};

export const updateEquipment = async (equipmentId, equipmentData) => {
    try {
        const response = await axios.put(`${UT_BASE_PATH}/updateEquipment/${equipmentId}`, equipmentData);
        return response.data;
    } catch (error) {
        console.error('Error updating equipment:', error);
        throw error;
    }
};

export const getEquipmentById = async (equipmentId) => {
    try {
        const response = await axios.get(`${UT_BASE_PATH}/getEquipmentById/${equipmentId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching equipment by ID:', error);
        throw error;
    }
};

export const getAllStandards = async () => {
    try {
        const response = await axios.get(`${UT_BASE_PATH}/getAllStandards`);
        return response.data;
    } catch (error) {
        console.error('Error fetching all Standards:', error);
        throw error;
    }
};

export const createStandard = async (standardData) => {
    try {
        const response = await axios.post(`${UT_BASE_PATH}/createStandard`, standardData);
        return response.data;
    } catch (error) {
        console.error('Error creating standard:', error);
        throw error;
    }
};

export const deleteStandard = async (standardId) => {
    try {
        const response = await axios.delete(`${UT_BASE_PATH}/deleteStandard/${standardId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting standard:', error);
        throw error;
    }
};

export const updateStandard = async (standardId, standardData) => {
    try {
        const response = await axios.put(`${UT_BASE_PATH}/updateStandard/${standardId}`, standardData);
        return response.data;
    } catch (error) {
        console.error('Error updating standard:', error);
        throw error;
    }
};

export const getStandardById = async (standardId) => {
    try {
        const response = await axios.get(`${UT_BASE_PATH}/getStandardById/${standardId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching standard by ID:', error);
        throw error;
    }
};
