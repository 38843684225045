import React, { useEffect, useState } from 'react'

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import {IconButton, Typography} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAllNeft_bank,createNeft_bank,deleteNeft_bank,getNeft_bankById,updateNeft_bank} from '../../lib/api-neft_bank';
import Modal from '@mui/material/Modal';
import { Box,Button, Grid,TextField } from "@mui/material";
import LoadingSpinner from '../../components/common/LoadingSpinner';




const style = {
 
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', 
  maxWidth: '50vw', 
  height: 'auto', 
  borderRadius:"2%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  height: 'flex',
  borderRadius: '2%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));




const NEFT = () => {


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  
  const [openEditModal, setOpenEditModal] = useState(false);
  const[searchText, setSearchText]=useState('')
  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleClose = () => {
    setOpen(false);
    setCompanyName('');
    setBankName('');
    setAccountNumber('');
    setBranchName('');
    setIfscCode('');
    setErrorAcc('');
    setErrorBank('');
    setErrorBranch('');
    setErrorCompany('');
    setErrorIfsc('');
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setCompanyName('');
    setBankName('');
    setAccountNumber('');
    setBranchName('');
    setIfscCode('');
    setErrorAcc('');
    setErrorBank('');
    setErrorBranch('');
    setErrorCompany('');
    setErrorIfsc('');
  };
  const [banks, setBanks] = useState([]);
  const[companyName,setCompanyName]=useState('');
  const[bankName,setBankName]=useState('');
  const[branchName,setBranchName]=useState('');
  const[accountNumber,setAccountNumber]=useState('');
  const[ifscCode,setIfscCode]=useState('');
  const[bID,setBID] = useState(null);
  const [loading, setLoading] = useState(false);

  const[errorCompany,setErrorCompany]= useState('');
  const[errorBank,setErrorBank]= useState('');
  const[errorBranch,setErrorBranch]= useState('');
  const[errorAcc,setErrorAcc]=useState('');
  const[errorIfsc,setErrorIfsc]= useState('');
  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (account_no) =>
  {
    setOpen2(true);
    setBID(account_no);

  }
  
    const fetchData = async () => {
      try {
        setLoading(true)
        const data = await getAllNeft_bank();
        const bankWithIds = data.map((row,index) =>(
          {
            id:row.bank_id,
            SrNo:index+1,
            ...row
          }
        ));
        setBanks(bankWithIds)
      } catch (error) {
        console.error('Error fetching bank data:', error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  }
  
  const handleAddBank = async () => {


    let isValid= true;

    if (companyName.trim() === '') {
      setErrorCompany('Company name is required');
      isValid=false;
    }
    else
    {
      setErrorCompany('');
    }


    if (bankName.trim() === '') {
      setErrorBank('Bank name is required');
      isValid=false;
    }
    else
    {
      setErrorBank('')
    }

    if (branchName.trim() === '') {
      setErrorBranch('Branch name is required');
      isValid=false;
    }
    else
    {
      setErrorBranch('');
    }

    if (accountNumber.trim() === '') {
      setErrorAcc('Account number is required');
      isValid=false;
    }
    else if (accountNumber.length !=15)
    {
      setErrorAcc('Account number should be 15');
      isValid=false;
    }
    else
    {
      setErrorAcc('');
    }

    if (ifscCode.trim() === '') {
      setErrorIfsc('IFSC code is required');
      isValid=false;
    }
    else if (ifscCode.length != 11)
    {
      setErrorIfsc('IfscCode number should be 11');
      isValid=false;
    }
    else
    {
      setErrorIfsc('');
    }

   if(isValid)
   {
    try {
      setLoading(true)
      await createNeft_bank({
        company_name:companyName,
        bank_name:bankName,
        branch_name:branchName,
        account_no:accountNumber,
        ifsc_code:ifscCode});
      handleClose();
      fetchData();
    } catch (error) {
      console.error('Error adding bank:', error);
    }finally{
          setLoading(false)
        }
   }
  };

  const handleDeleteBank = async () =>
  {
    try {
      setLoading(true)
      await deleteNeft_bank(bID);
      setOpen2(false);
      fetchData();
      setSearchText('');

    } catch (error) {
      console.log("Error in deleting Bank" , error);
    }finally{
          setLoading(false)
        }
  }

  const getBank = async(bankId) =>
  {
    handleOpenEditModal();
    setBID(bankId)
    try {
      const bank = await getNeft_bankById(bankId);
      const jsonData = bank.map((row)=>{
        setCompanyName(row.company_name);
        setBankName(row.bank_name);
        setBranchName(row.branch_name);
        setAccountNumber(row.account_no);
        setIfscCode(row.ifsc_code);
      })
      
    } catch (error) {
      console.log("Error in getting bank by ID", error);
    }
  };

  const updateBank = async() =>{

    let isValid= true;

    if (companyName.trim() === '') {
       setErrorCompany('Company name is required');
      isValid=false;
    }
    else
    {
      setErrorCompany('');
    }


    if (bankName.trim() === '') {
       setErrorBank('Bank name is required');
      isValid=false;
    }
    else
    {
      setErrorBank('')
    }

    if (branchName.trim() === '') {
       setErrorBranch('Branch name is required');
      isValid=false;
    }
    else
    {
      setErrorBranch('');
    }

    if (accountNumber.trim() === '') {
       setErrorAcc('Account number is required');
      isValid=false;
    }
    else
    {
      setErrorAcc('');
    }

    if (ifscCode.trim() === '') {
       setErrorIfsc('IFSC code is required');
      isValid=false;
    }
    else if(ifscCode.length !== 11){
      setErrorIfsc('IFSC Should be 11 Digits');
      isValid=false;
    }
    else
    {
      setErrorIfsc('');
    }
    if(isValid)
    {
      
    try {
      const updatedBankData = {
        company_name: companyName,
        bank_name:bankName,
        branch_name:branchName,
        account_no:accountNumber,
        ifsc_code:ifscCode,
      };
      setLoading(true)
      await updateNeft_bank(bID,updatedBankData);
      handleCloseEditModal();
      fetchData();
      setSearchText('');

    } catch (error) {
      console.log("Error in updating Bank", error);
      
    }finally{
          setLoading(false)
        }
    }
  };
  const columns= [
    { field: 'SrNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'bank_name', headerName: 'Bank Name', flex: 1  },
    { field: 'branch_name', headerName: 'Branch Name', flex: 1  },
    {field: 'account_no',headerName: 'Account Number',flex: 1 },
    {field: 'ifsc_code',headerName: 'IFSC Code', sortable: false,flex: 1 },
    {field:'action',headerName:'Action',flex:1,
      renderCell:(params)=>(
        <div>
       <IconButton onClick={() => getBank(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.account_no)} >
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
        </div>
      )
    }
  ];

  function filterBank(bank, searchText) {
    return (
      (bank.company_name && bank.company_name.toLowerCase().includes(searchText.toLowerCase())) ||
      (bank.bank_name && bank.bank_name.toLowerCase().includes(searchText.toLowerCase())) ||
      (bank.branch_name && bank.branch_name.toLowerCase().includes(searchText.toLowerCase())) ||
      (bank.account_no && bank.account_no.toLowerCase().includes(searchText.toLowerCase())) ||
      (bank.ifsc_code && bank.ifsc_code.toLowerCase().includes(searchText.toLowerCase())) 
    );
  }
  return (
    <div>
      {loading && <LoadingSpinner/>}
               <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>NEFT Bank</h1> 

        <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
              <div style={{marginRight:"40px"}}>
                    <Search>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchText}
                          onChange={handleSearch}
                        />
                      </Search>
                </div>
               <div>
                    <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Bank">Add Bank</Button>
               </div>
            
        </div>


        <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
      <DataGrid
        density='compact'
        rows={banks.filter((bank)=> filterBank(bank,searchText))}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
       
      />
    </div>

    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Comapny Name"
            size="small"
            variant="outlined"
            value={companyName}
            onChange={(e)=>setCompanyName(e.target.value)}
            error={errorCompany !==''}
            helperText={errorCompany}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Bank Name"
            size="small"
            variant="outlined"
            value={bankName}
            onChange={(e)=>setBankName(e.target.value)}
            error={errorBank !==''}
            helperText={errorBank}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Branch Name"
            size="small"
            variant="outlined"
            value={branchName}
            onChange={(e)=>setBranchName(e.target.value)}
            error={errorBranch !== ''}
            helperText={errorBranch}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Account No"
            size="small"
            variant="outlined"
            value={accountNumber}
            onChange={e=>setAccountNumber(e.target.value)}
            error={errorAcc !== ''}
            helperText={errorAcc}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
              className="customTextField"
              id="outlined-basic"
              label="IFSC Code"
              size="small"
              variant="outlined"
              value={ifscCode}
              onChange={(e)=>setIfscCode((e.target.value).toUpperCase())}
              error={errorIfsc !==''}
              helperText={errorIfsc}
              fullWidth
            />
          
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" onClick={handleAddBank} fullWidth>
              Save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>
          
      


    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={openEditModal}
      onClose={handleCloseEditModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Comapny Name"
            size="small"
            variant="outlined"
            value={companyName}
            onChange={(e)=>setCompanyName(e.target.value)}
            error={errorCompany !==''}
            helperText={errorCompany}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Bank Name"
            size="small"
            variant="outlined"
            value={bankName}
            onChange={(e)=>setBankName(e.target.value)}
            error={errorBank !==''}
            helperText={errorBank}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Branch Name"
            size="small"
            variant="outlined"
            value={branchName}
            onChange={(e)=>setBranchName(e.target.value)}
            error={errorBranch !== ''}
            helperText={errorBranch}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Account No"
            size="small"
            variant="outlined"
            value={accountNumber}
            onChange={e=>setAccountNumber(e.target.value)}
            error={errorAcc !== ''}
            helperText={errorAcc}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
              className="customTextField"
              id="outlined-basic"
              label="IFSC Code"
              size="small"
              variant="outlined"
              value={ifscCode}
              onChange={(e)=>setIfscCode(e.target.value)}
              error={errorIfsc !==''}
              helperText={errorIfsc}
              fullWidth
            />
          
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" onClick={updateBank} fullWidth>
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseEditModal}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>

    
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style1}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteBank}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
    </div>
  )
}

export default NEFT;



