import React ,{useEffect,useState} from 'react'
import * as Yup from 'yup';
import {useFormik} from "formik";
import { DataGrid } from '@mui/x-data-grid';
import dayjs from "dayjs";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import {Autocomplete, IconButton, Chip, FormControlLabel, FormGroup, Checkbox, Typography} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { Select, MenuItem, InputLabel , FormHelperText } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import {getMagneticById,updateMagnetic,createMagnetic, getAutoCertificateNo,  deleteMagneticDescription1, getPrint, getMagneticPDF} from "../../../lib/api-magnetic";
import { getAllCustomersAuto } from '../../../lib/api-customer';

import { getAllMaterials } from '../../../lib/api-material';
import { getAllInstruments } from '../../../lib/api-instrument';
import { getAllTechnique } from '../../../lib/api-technique';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from "../../../components/common/CustomSnackbar";



const CreateMagnetic = ({magneticId,isEdit}) => {
    const[cust,setCust]=useState([]);
    const[mate,setMate]=useState([]);
    const[instru,setInstru]=useState([]);
    const[tech,setTech]=useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValues1, setSelectedValues1] = useState([]);
    const [selectedValues2, setSelectedValues2] = useState([]);
    const [loading, setLoading] = useState(false);
    const [latestId,setLatestId]=useState(false)
    const [openPDFModal, setOpenPDFModal] = useState(false);
    const [ pdfData, setPdfData] = useState(null);
    const handlePDFModalClose = ()=>{
      setPdfData(null)
      setOpenPDFModal(false)};
      const navigate = useNavigate();
      const handleBack = ()=>
      {
        navigate('/Magnetic')

      }
      const [Modals, setModal] = useState({
        option1: false,
        option2: false,
        option3: false,
        option4: false
    });

    const [Sr_Nos ,setSrNo] = useState({
      option1: false,
      option2:false,
      option3:false,
      option4:false,
      option5:false,
      option6:false,
      option7:false,
      option8:false,
      option9:false,
      option9:false,
      option10:false,
      option11:false,
      option12:false,
    })

    const [Types,setType]= useState({
      option1:false,
      option2:false,
    })

    const [rows, setRows] = useState([]);
  const [formikValues, setFormikValues] = useState({
    Description: '',
    Qty: '',
    Weight: '',
    Accepted: '',
    Rejected: '',
    Remarks: '',
    
  });
  const [idCounter, setIdCounter] = useState(1);
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormikValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleAddRow = () => {
    if (!formikValues.Description || !formikValues.Qty || !formikValues.Weight || !formikValues.Accepted || !formikValues.Rejected || !formikValues.Remarks) {
        alert("Please fill in all required fields.");
        return; 
    }

    const newRow = { id: idCounter, srNo: idCounter, ...formikValues };
    setIdCounter(idCounter + 1);

    if (rows.length > 0) {
        const maxSrNo = Math.max(...rows.map(row => row.srNo));
        newRow.srNo = maxSrNo + 1;
    } else {
        newRow.srNo = 1;
    }

    newRow.id = newRow.srNo;

    setRows(prevRows => [...prevRows, newRow]);

    setFormikValues({
        Description: '',
        Qty: '',
        Weight: '',
        Accepted: '',
        Rejected: '',
        Remarks: '',
    });
};




const handleDelete3 = (id) => {
  deleteMagneticDescription1(id)
      .then(response => {
          console.log(response.data); 
          setRows(prevRows => prevRows.filter(row => row.id !== id));
      })
      .catch(error => {
          console.error('Error deleting row:', error); // Handle error
      });
};


      const initalValues = {
        Cert_Date:new Date(),
        Certificate_No:'',
        Cust_Name:{value:'', label:''},
        Referance_Id:'',

        Material_Nm:[{value:'',label:''}],
        Acceptance_Std:'ASTM.E 709',
        Applicable_Spec:'ASTM.E 709',
        Scope_Exam:'All Surface Area',

        Instrument_Use:[{value:'',label:''}],
        Method:'',
        Magnaglo:'',
        Black_Light:'',

        Sr_No:'',
        Intensity:'',
        White_Light:'',

        technician:'',
        Type:'',
        Ampere:'',
        Current:'A/C-HWDC',
        Batch_No:'',
        Modal:'',
        
        Demag:'',
        Location:'',
        Witness_By:'',
        Technique:[{value:'',label:''}],
        designation:'ASNT LEVEL II - UT.MT.PT'
      }

      const columns = [
        { field: 'srNo', headerName: 'Sr.No.',  flex:1 },
        { field: 'Description', headerName: 'Description',flex: 1 },
        { field: 'Qty', headerName: 'Qty', flex: 1 },
        { field: 'Weight', headerName: 'Weight', flex: 1 },
        { field: 'Accepted', headerName: 'Accepted', flex: 1 },
        { field: 'Rejected', headerName: 'Rejected', flex: 1 },
        { field: 'Remarks', headerName: 'Remarks', flex: 1 },
        { field: 'action', headerName: 'Action', flex: 1,
        renderCell: (params) => (
          <div>
          <IconButton   onClick={()=> handleDelete3(params.row.id) }>
            <DeleteIcon sx={{ color: 'red' }} />
          </IconButton>
        </div>
        ),},
      ];

     




      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllCustomersAuto();
            setCust(() => {
              const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name } });
              return customerOp; 
          });
          } catch (error) {
            console.error("Error fetching customers:", error);
          }
        };
        fetchData();
      },[])


      
      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllMaterials();
            const materialOp = response.map((row) => { return { value: row.Material_Id, label: row.Material_Nm } });
            setMate(materialOp);
          } catch (error) {
            console.error("Error fetching materials:", error);
          }
        };
        fetchData();
      },[])

      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllInstruments();
            const instrumentOp = response.map((row) => { return { value: row.instrument_id, label: row.Instrument_name } });
            setInstru(instrumentOp);
          } catch (error) {
            console.error("Error fetching instruments:", error);
          }
        };
        fetchData();
      },[])

      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllTechnique();
            const TechniqueOp = response.map((row) => { return { value: row.tech_id, label: row.tech_name } });
            setTech(TechniqueOp);
          } catch (error) {
            console.error("Error fetching Technique:", error);
          }
        };
        fetchData();
      },[])

      useEffect(() => {
        const fetchCertificateNo = async () => {
          try {
            const getCertificateNo = await getAutoCertificateNo();
            const maxNumber = getCertificateNo.data.nextCertificateNo;
            formik.setFieldValue('Certificate_No' , maxNumber) ;

          } catch (error) {
            
          }
        }
        fetchCertificateNo();
      },[])

   

      useEffect(() => {
        const fetchData = async () => {
            try {
              setLoading(true);
                const getMagneticData = await getMagneticById(magneticId);
                setLatestId(true)
                const magneticData = getMagneticData.data.magnetic;
    
                const [selectedTypeOptions, selectedSr_NoOptions, selectedModalOptions] = await Promise.all([
                  JSON.parse(magneticData.Type),
                  JSON.parse(magneticData.Sr_No),
                  JSON.parse(magneticData.Modal)
              ]);
  
              setType(selectedTypeOptions);
              setSrNo(selectedSr_NoOptions);
              setModal(selectedModalOptions);
  
              const parseDate = (dateString) => {
                if (dateString) {
                    const parts = dateString.split('/');
                    return `${parts[2]}-${parts[1]}-${parts[0]}`;
                }
                return null;
            };

                const [MaterialValues, TechniqueValues, InstrumentValues] = await Promise.all([
                  JSON.parse(magneticData.Material_Test),
                  JSON.parse(magneticData.Technique_Test),
                  JSON.parse(magneticData.Instrument_Use)
              ]);

                const selectedOptions = MaterialValues.map(value => ({
                  value,
                  label: mate.find(option => option.value === value)?.label || ''
              }));
              setSelectedValues(selectedOptions);
  
              const selectedOptions1 = TechniqueValues.map(value => ({
                  value,
                  label: tech.find(option => option.value === value)?.label || ''
              }));
              setSelectedValues2(selectedOptions1);
  
              const selectedOptions2 = InstrumentValues.map(value => ({
                  value,
                  label: instru.find(option => option.value === value)?.label || ''
              }));
              setSelectedValues1(selectedOptions2);
    
                const CustNew = cust.filter((item) => item.value == magneticData.Cust_Name)[0];
    
                
                const sortedData = {
                    Cert_Date:parseDate(magneticData.Cert_Date),
                    Cust_Name: CustNew,
                    Certificate_No: magneticData.Certificate_No,
                    Referance_Id: magneticData.Referance_Id,
                    Material_Nm: selectedOptions,
                    Applicable_Spec: magneticData.Applicable_Spec,
                    Acceptance_Std: magneticData.Acceptance_Std,
                    Scope_Exam: magneticData.Scope_Exam,
                    Method: magneticData.Method,
                    Instrument_Use: selectedOptions2,
                    Magnaglo: magneticData.Magnaglo,
                    Batch_No: magneticData.Batch_No,
                    Black_Light: magneticData.Black_Light,
                    Modal: selectedModalOptions,
                    Sr_No: selectedSr_NoOptions,
                    Type: selectedTypeOptions, 
                    Intensity: magneticData.Intensity,
                    White_Light: magneticData.White_Light,
                    Technique: selectedOptions1, 
                    Ampere: magneticData.Ampere,
                    Current: magneticData.Current,
                    Demag: magneticData.Demag,
                    Witness_By: magneticData.Witness_By,
                    technician: magneticData.technician,
                    Location: magneticData.Location,
                    designation: magneticData.designation
                };
    
                formik.setValues(sortedData);
    
                const sortedRows = getMagneticData.data.rows.map((row, index) => ({
                    id: row.id,
                    srNo: index + 1,
                    ...row
                }));
                setRows(sortedRows);
                setLoading(false)
            } catch (error) {
                console.log("Error in Fetching Magnetic Data", error);
            }finally{
              setLoading(false);
            }
        };
        if (isEdit) {
            fetchData();
        }
    }, [isEdit, magneticId, cust, mate, tech, instru]);
    
    
    
     const formik = useFormik({
        initialValues:initalValues,
        validationSchema:Yup.object().shape({
          Cert_Date:Yup.date().notRequired(),
            Certificate_No:Yup.number().notRequired(),
            Cust_Name:Yup.object().notRequired(),
            Referance_Id:Yup.string().notRequired(),
            
            Material_Nm:Yup.array().notRequired(),
            Acceptance_Std:Yup.string().notRequired(),
            Applicable_Spec:Yup.string().notRequired(),
            Scope_Exam:Yup.string().notRequired(),

            Method:Yup.string().notRequired(),
            Instrument_Use:Yup.array().notRequired(),
            Magnaglo:Yup.string().notRequired(),
            Batch_No:Yup.string().notRequired(),
            Black_Light:Yup.string().notRequired(),
            Technique:Yup.array().notRequired(),
            Intensity:Yup.string().notRequired(),
            White_Light:Yup.string().notRequired(),
            Ampere:Yup.string().notRequired(),
            Current:Yup.string().notRequired(),
            Demag:Yup.string().notRequired(),
            Witness_By:Yup.string().notRequired(),
            technician:Yup.string().notRequired(),
            Location:Yup.string().notRequired(),
            designation:Yup.string().notRequired()
    
        }),
        onSubmit: async(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
              setLoading(true);
              values.Modal = Modals;
        values.Sr_No = Sr_Nos;
      
              let response = {};

              values.rows = rows;

              if (isEdit) {
                response = await updateMagnetic(magneticId, values);
                  setConfirmationMessage("Magnetic Updated successfully");
                  setIsConfirmationOpen(true);
                  setColor('green')
              } else {
                response = await createMagnetic(values);
                console.log("jkjhshlkjk",response);
                if(response.status==200){
                  setConfirmationMessage(response.data);
                  setIsConfirmationOpen(true);
              setColor('green')
                setLatestId(true);
                resetForm();
                setSelectedValues([]);
                setSelectedValues1([]);
                setSelectedValues2([]); 
                setRows([]);
                setType({});
                setSrNo({});
                setModal({});
              }
                else{
                  setConfirmationMessage("Somthing Went Wrong!");
              setIsConfirmationOpen(true);
              setColor('error')
                }
              }
            } catch (error) {
              setStatus({ success: false });
              setErrors({ submit: error.response.data.message });
              setSubmitting(false);
            }finally{
              setLoading(false);
            }
          }
          
      });

      const handleAutocompleteChange1 = (event, value) => {
        formik.setFieldValue('Instrument_Use', value);
        setSelectedValues1(value);
        
      };
      
      const handleDelete1 = (valueToDelete) => () => {
        setSelectedValues1((prevValues) =>
          prevValues.filter((value) => value.label !== valueToDelete.label)
        );
      };

      const handleAutocompleteChange2 = (event, value) => {
        formik.setFieldValue('Technique', value);
        setSelectedValues2(value);
        
      };
      
      const handleDelete2 = (valueToDelete) => () => {
        setSelectedValues2((prevValues) =>
          prevValues.filter((value) => value.label !== valueToDelete.label)
        );
      };
    
      const handleAutocompleteChange = (event, value) => {
        setSelectedValues(value);
        formik.setFieldValue('Material_Nm', value);
        
        
      };
      
     
      
  const handleDelete = (valueToDelete) => () => {
    setSelectedValues((prevValues) =>
      prevValues.filter((value) => value.label !== valueToDelete.label)
    );
  };

      const handleChangeC = (event) => {
        setModal({ ...Modals, [event.target.name]: event.target.checked });
        formik.setFieldValue('Modal', { ...Modals, [event.target.name]: event.target.checked }); 
    };

    const handleChangeS = (event) => {
      setSrNo({ ...Sr_Nos, [event.target.name]: event.target.checked });
      formik.setFieldValue('Sr_No', { ...Sr_Nos, [event.target.name]: event.target.checked }); 
  };

  const handleChangeT = (event) => {
    setType({ ...Types, [event.target.name]: event.target.checked });
    formik.setFieldValue('Type', { ...Types, [event.target.name]: event.target.checked }); 
};

const handlep=()=>{
  if(magneticId){
    handlePrintforedit()
  }else{
    handlePrint()
  }
}

const handlePrintforedit = async()=>{
  try {
    setLoading(true);
    const getPDF = await getMagneticPDF(magneticId);
    setOpenPDFModal(true);
    const filepath=getPDF.data.returnpath
    setPdfData(filepath);
    
  } catch (error) {
    console.error("Error while printing:", error);
  }finally{
    setLoading(false);

  }
}

const handlePrint= async()=>{
  
  try {
    setLoading(true)
    const response = await getPrint();
    
    setOpenPDFModal(true);
    const filepath=response.returnpath
  
    setPdfData(filepath);
  } catch (error) {
    console.error("Error fetching customers:", error);
  }finally{
    setLoading(false)
  }

}

  return (
    
    <div>
      {loading && <LoadingSpinner/>}
        <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
              <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
              <FormControl style={{width:"180px"}}><LocalizationProvider dateAdapter={AdapterDayjs}> <DatePicker 
              name="Cert_Date" 
              format="DD/MM/YYYY" 
              label="Date" 
              errors={formik.errors}  
              value={formik.values.Cert_Date ? dayjs(formik.values.Cert_Date) : null}
              // onChange={(date) => {formik.setFieldValue('Cert_Date', date);}}
              onChange={(date) => {
                formik.setFieldValue(
                  "Cert_Date",
                  date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
                );
              }}/> </LocalizationProvider>
              </FormControl> 
                  <TextField style={{width:"37.5vw"}}   id="outlined-basic" label="Certificate No." variant="outlined"  name='Certificate_No'  error={formik.errors.Certificate_No && formik.touched.Certificate_No}
                      helperText={formik.touched.Certificate_No ? formik.errors.Certificate_No : ''}
                      onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Certificate_No} /> 
              </div>
              <div>
              <Autocomplete
          options={cust}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Cust_Name', value || null)}
          value={formik.values.Cust_Name}
          renderInput={(params) => (
            <TextField
            {...params}
            sx={{width:"50vw"}}
            label="Customer Name"
            variant="outlined"
            name="Cust_Name"
            error={formik.errors.Cust_Name && formik.touched.Cust_Name}
            helperText={formik.touched.Cust_Name ? formik.errors.Cust_Name : ''}
            onBlur={formik.handleBlur}
            />
          )}
        />
            
        </div>
        <div>
            <TextField style= {{width:"50vw"}}   id="outlined-basic" label="Referance" name='Referance_Id' variant="outlined" 
            error={formik.errors.Referance_Id && formik.touched.Referance_Id}
            helperText={formik.touched.Referance_Id ? formik.errors.Referance_Id : ''}
             onBlur={formik.handleBlur}
           onChange={formik.handleChange}
           value={formik.values.Referance_Id}/>
          
        </div>
           
        </div>

        <br/>
        <div >
        <div>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1%' }}>

      
      
        <TextField
          id="outlined-basic"
          label="Part Description"
          name="Description"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Description}
        />

        <TextField
          id="outlined-basic"
          label="Qty"
          name="Qty"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Qty}
        />

        <TextField
          id="outlined-basic"
          label="Weight"
          name="Weight"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Weight}
        />

        <TextField
          id="outlined-basic"
          label="Accepted"
          name="Accepted"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Accepted}
        />

        <TextField
          id="outlined-basic"
          label="Rejected"
          name="Rejected"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Rejected}
        />

        <TextField 
          id="outlined-basic" 
          label="Remarks" 
          name='Remarks' 
          variant="outlined" 
          style={{ width: "10vw" }} 
          onChange={handleChange}
          value={formikValues.Remarks}
        />
        <Button onClick={handleAddRow} style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>
      </div>
      <div style={{ marginBlock: "20px" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
     
    </div>
        </div>
       
      <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr 1fr 1fr", gap:"2rem", marginTop:"3vh",marginBottom:"0.5vh"}}>
                  
                  <Autocomplete
                    multiple
                    options={mate}
                    getOptionLabel={(option) => option.label}
                    onChange={handleAutocompleteChange}
                    value={selectedValues}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='outlined-basic'
                        label='Material Name'
                        name='Material_Nm'
                        variant='outlined'
                        fullWidth
                      />
                    )}
                    renderTags={(value) =>
                      value.map((option, index) => (
                        <div key={index} style={{ display: 'flex', overflowX: 'auto', maxWidth: '100%' }}>
                          <Chip
                            key={index}
                            label={option ? option.label : ""}
                            variant="outlined"
                            onDelete={handleDelete(option)}
                            sx={{ margin: '2px' }}
                          />
                        </div>
                      ))
                    }
                  />

                  <TextField   id="outlined-basic" label="Acceptance Standard" name='Acceptance_Std' variant="outlined" error={formik.errors.Acceptance_Std && formik.touched.Acceptance_Std}
                      helperText={formik.touched.Acceptance_Std ? formik.errors.Acceptance_Std : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Acceptance_Std} />

                  <TextField   id="outlined-basic" label="Application Specification" name='Applicable_Spec' variant="outlined" error={formik.errors.Applicable_Spec && formik.touched.Applicable_Spec}
                      helperText={formik.touched.Applicable_Spec ? formik.errors.Applicable_Spec : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Applicable_Spec} />
                  <TextField   id="outlined-basic" label="Scope of Examination" name='Scope_Exam' variant="outlined" error={formik.errors.Scope_Exam && formik.touched.Scope_Exam}
                      helperText={formik.touched.Scope_Exam ? formik.errors.Scope_Exam : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Scope_Exam} />

                  <Autocomplete
                    multiple
                    options={instru}
                    getOptionLabel={(option) => option.label}
                    onChange={handleAutocompleteChange1}
                    value={selectedValues1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='outlined-basic'
                        label='Instrument Used'
                        name='Instrument_Use'
                        variant='outlined'
                        fullWidth
                      />
                    )}
                    renderTags={(value) =>
                      value.map((option, index) => (
                        <div key={index} style={{ display: 'flex', overflowX: 'auto', maxWidth: '100%' }}>
                          <Chip
                            key={index}
                            label={option ? option.label : ""}
                            variant="outlined"
                            onDelete={handleDelete1(option)}
                            sx={{ margin: '2px' }}
                          />
                        </div>
                      ))
                    }
                  />

                  <FormControl>
                      <InputLabel id="method-label">Method</InputLabel>
                        <Select
                          labelId="method-label"
                          value={formik.values.Method}
                          onChange={formik.handleChange}
                          label="Method"
                          name="Method"
                          error={formik.errors.Method && formik.touched.Method}
                          onBlur={formik.handleBlur}
                        >
                          <MenuItem value="Fluorescent">Fluorescent</MenuItem>
                          <MenuItem value="Wet-Visible">Wet-Visible</MenuItem>

                        </Select>
                        {formik.errors.Method && formik.touched.Method  && (
                      <FormHelperText error>
                        {formik.errors.Method}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <TextField   id="outlined-basic" label="Magnaglo" name='Magnaglo' variant="outlined" error={formik.errors.Magnaglo && formik.touched.Magnaglo}
                      helperText={formik.touched.Magnaglo ? formik.errors.Magnaglo : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Magnaglo} />
                  <TextField   id="outlined-basic" label="Black Light" name='Black_Light' variant="outlined" error={formik.errors.Black_Light && formik.touched.Black_Light}
                      helperText={formik.touched.Black_Light ? formik.errors.Black_Light : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Black_Light} />

<div>
<InputLabel id="modal-checkbox">Modal</InputLabel>
            <FormGroup id="modal-checkbox">
                <FormControlLabel
                    control={<Checkbox checked={Modals.option1} onChange={handleChangeC} name="option1" />}
                    label="1500 AC"
                />
                <FormControlLabel
                    control={<Checkbox checked={Modals.option2} onChange={handleChangeC} name="option2" />}
                    label="1500 HWDC/AC"
                />
                <FormControlLabel
                    control={<Checkbox checked={Modals.option3} onChange={handleChangeC} name="option3" />}
                    label="CD-1250"
                />
                <FormControlLabel
                    control={<Checkbox checked={Modals.option4} onChange={handleChangeC} name="option4" />}
                    label="HWDC"
                />
            </FormGroup>
</div>

<div >
<InputLabel id="sr-checkbox">Sr. No</InputLabel>
<div style={{maxHeight:"27vh" ,overflowY:"auto"}}>
            <FormGroup id="sr-checkbox">
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option1} onChange={handleChangeS} name="option1" />}
                    label="1"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option2} onChange={handleChangeS} name="option2" />}
                    label="2"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option3} onChange={handleChangeS} name="option3" />}
                    label="3"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option4} onChange={handleChangeS} name="option4" />}
                    label="4"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option5} onChange={handleChangeS} name="option5" />}
                    label="5"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option6} onChange={handleChangeS} name="option6" />}
                    label="6"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option7} onChange={handleChangeS} name="option7" />}
                    label="7"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option8} onChange={handleChangeS} name="option8" />}
                    label="8"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option9} onChange={handleChangeS} name="option9" />}
                    label="9"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option10} onChange={handleChangeS} name="option10" />}
                    label="10"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option11} onChange={handleChangeS} name="option11" />}
                    label="11"
                />
                <FormControlLabel
                    control={<Checkbox checked={Sr_Nos.option12} onChange={handleChangeS} name="option12" />}
                    label="12"
                />
            </FormGroup>
            </div>
</div>

<div >
  
                     <InputLabel id="modal-checkbox">Type</InputLabel>
            <FormGroup id="modal-checkbox">
                <FormControlLabel
                    control={<Checkbox checked={Types.option1} onChange={handleChangeT} name="option1" />}
                    label="135-A"
                />
                <FormControlLabel
                    control={<Checkbox checked={Types.option2} onChange={handleChangeT} name="option2" />}
                    label="146-A"
                />

            </FormGroup>
                     </div>




                  <TextField   id="outlined-basic" label="Intensity" name='Intensity' variant="outlined" error={formik.errors.Intensity && formik.touched.Intensity}
                      helperText={formik.touched.Intensity ? formik.errors.Intensity : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Intensity} />

                  <TextField   id="outlined-basic" label="White Light Intensity" name='White_Light' variant="outlined" error={formik.errors.White_Light && formik.touched.White_Light}
                      helperText={formik.touched.White_Light ? formik.errors.White_Light : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.White_Light} />


                    <Autocomplete
                          multiple
                          options={tech}
                          getOptionLabel={(option) => option.label}
                          onChange={handleAutocompleteChange2}
                          value={selectedValues2}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id='outlined-basic'
                              label='Technique'
                              name='Technique'
                              variant='outlined'
                              fullWidth
                            />
                          )}
                          renderTags={(value) =>
                            value.map((option, index) => (
                              <div key={index} style={{ display: 'flex', overflowX: 'auto', maxWidth: '100%' }}>
                                <Chip
                                  key={index}
                                  label={option ? option.label : ""}
                                  variant="outlined"
                                  onDelete={handleDelete2(option)}
                                  sx={{ margin: '2px' }}
                                />
                              </div>
                            ))
                          }
                        />
                
                  <TextField   id="outlined-basic" label="Ampere" name='Ampere' variant="outlined" error={formik.errors.Ampere && formik.touched.Ampere}
                      helperText={formik.touched.Ampere ? formik.errors.Ampere : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Ampere} />
                  <TextField   id="outlined-basic" label="Current" name='Current' variant="outlined" error={formik.errors.Current && formik.touched.Current}
                      helperText={formik.touched.Current ? formik.errors.Current : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Current} />

                  <TextField   id="outlined-basic" label="Batch No" name='Batch_No' variant="outlined" error={formik.errors.Batch_No && formik.touched.Batch_No}
                      helperText={formik.touched.Batch_No ? formik.errors.Batch_No : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Batch_No} />
                  <TextField   id="outlined-basic" label="Demag" name='Demag' variant="outlined" error={formik.errors.Demag && formik.touched.Demag}
                      helperText={formik.touched.Demag ? formik.errors.Demag : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Demag} />
                  <TextField   id="outlined-basic" label="Location" name='Location' variant="outlined" error={formik.errors.Location && formik.touched.Location}
                      helperText={formik.touched.Location ? formik.errors.Location : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Location} />

                  <TextField   id="outlined-basic" label="Witness By" name='Witness_By' variant="outlined" error={formik.errors.Witness_By && formik.touched.Witness_By}
                      helperText={formik.touched.Witness_By ? formik.errors.Witness_By : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Witness_By} />
                  <TextField   id="outlined-basic" label="Tecnician" name='technician' variant="outlined" error={formik.errors.technician && formik.touched.technician}
                      helperText={formik.touched.technician ? formik.errors.technician : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.technician} />

                  <TextField   id="outlined-basic" label="designation" name='designation' variant="outlined" error={formik.errors.designation && formik.touched.designation}
                      helperText={formik.touched.designation ? formik.errors.designation : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.designation} />

        </div>
                <div style={{display:"flex", justifyContent:"flex-end",}}>
                    <Button onClick={handlep} style={{ backgroundColor: latestId ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity: latestId ? 1 : 0.5 }} disabled={!latestId}>PRINT</Button>

                    <Button
                    onClick={formik.handleSubmit}
                    style={{
                    backgroundColor: "Green",
                    color: "white", 
                    marginInline: "0.7rem", 
                   
                    }}
                    
                    >
                    {magneticId === undefined ? "Save" : "Update"}
                    </Button>

                    <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
                    <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>
                </div>

                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />     
        
    </div>
   
  )
}

export default CreateMagnetic;
