import {useFormik} from "formik";
import * as Yup from 'yup';
import dayjs from "dayjs";
import React, { useEffect,useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {Autocomplete, Checkbox, FormControlLabel, IconButton} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useNavigate } from 'react-router-dom';
import { getAllCustomersAuto } from "../../../lib/api-customer";
import { getPrint,getDyeById,updateDye,createDye, getAutoCertificateNo, deleteDyeDescription, getDyePDF} from "../../../lib/api-dye";
import { Select, MenuItem, InputLabel , FormHelperText } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import {Dialog, DialogActions, DialogTitle} from '@mui/material';
import { APP_BASE_PATH } from "../../../lib/api-base-paths";
import CustomSnackbar from "../../../components/common/CustomSnackbar";

const CreateDyes = ({dyeId,isEdit}) => {
    const[cust,setCust]=useState([]);
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const [color,setColor]=useState("")
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dye,setDye]=useState(false)
    const [rows, setRows] = useState([]);
  const [formikValues, setFormikValues] = useState({
    Description: '',
    Qty: '',
    Weight: '',
    Accepted: '',
    
    Rejected: '',
    Remarks: '',
    customRemarks: ''
  });
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [ pdfData, setPdfData] = useState(null);
  const [idCounter, setIdCounter] = useState(1);
  const handlePDFModalClose = ()=>{
    setPdfData(null)
    setOpenPDFModal(false)};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormikValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


const handleAddRow = () => {
 
  if (!formikValues.Description || !formikValues.Qty || !formikValues.Weight || !formikValues.Accepted || !formikValues.Rejected || !formikValues.Remarks) {
      alert("Please fill in all required fields.");
      return; 
  }

  const newRow = { id: idCounter, srNo: idCounter, ...formikValues };
  setIdCounter(idCounter + 1);

  if (rows.length > 0) {
      const maxSrNo = Math.max(...rows.map(row => row.srNo));
      newRow.srNo = maxSrNo + 1;
  } else {
      newRow.srNo = 1;
  }

  newRow.id = newRow.srNo;

  newRow.Remarks = formikValues.Remarks === 'Other' ? formikValues.customRemarks : formikValues.Remarks;

  setRows(prevRows => [...prevRows, newRow]);

  setFormikValues({
      Description: '',
      Qty: '',
      Weight: '',
      Accepted: '',
      Rejected: '',
      Remarks: '',
      customRemarks: ''
  });
};




const handleDelete = (id) => {
  deleteDyeDescription(id)
      .then(response => {
          console.log(response.data); 
          setRows(prevRows => prevRows.filter(row => row.id !== id));
      })
      .catch(error => {
          console.error('Error deleting row:', error); // Handle error
      });
};

   

  
    const initalValues= {
      Cert_Date:new Date(),
      Certificate_No: '' ,
      Cust_Name:{value:'',label:''},
      Referance_Id:'',
      Test_Spec:'',
      Acceptance_Std:'ASTM-E 165',
      Applicable_Spec:'ASTM-E 165',
      Scope_Exam:'',
      Method:'',
      Penetrant:'Solvent Removable',
      Penetrant_time:'',
      Batch_No1:'',
      Examination:'',
      Developer:'Spray',
      Developer_time:'',
      Application:'',
      Cleaner_Type:'',
      Black_Light:'',
      Batch_No2:'',
      Batch_NoExam:'',
      UV_No:'',
      Witness_By:'',
      technician:'',
      Location:'',
      designation:'ASNT LEVEL II - UT.MT.PT',
    }

      const navigate=useNavigate();
      const handleBack =()=>
      {
        navigate('/Dye');
      }

      const columns = [
        { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
        { field: 'Description', headerName: 'Part Description', flex: 1 },
        { field: 'Qty', headerName: 'Qty', flex: 1 },
        { field: 'Weight', headerName: 'Weight', flex: 1 },
        { field: 'Accepted', headerName: 'Accepted', flex: 1 },
        { field: 'Rejected', headerName: 'Rejected', flex: 1 },
        { field: 'Remarks', headerName: 'Remarks', flex: 1 },
        {
          field: 'action',
          headerName: 'Action',
          flex: 1,
          renderCell: (params) => (
            <div>
            <IconButton   onClick={()=> handleDelete(params.row.id) }>
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          </div>
          
          ),
        },
      ];

          
      useEffect(()=>{
        const fetchData = async () => {
          try {
            const response = await getAllCustomersAuto();
            setCust(() => {
              const customerOp = response.map((row) => { return { value: row.Cust_Id, label: row.Cust_Name } });
              return customerOp; 
          });
          } catch (error) {
            console.error("Error fetching customers:", error);
          }
        };
        fetchData();
      },[])


      useEffect(() => {
        const fetchCertificateNo = async () => {
          try {
            const getCertificateNo = await getAutoCertificateNo();
            const maxNumber = getCertificateNo.data.nextCertificateNo;
            formik.setFieldValue('Certificate_No' , maxNumber) ;

          } catch (error) {
            
          }
        }
        fetchCertificateNo();
      },[])

      const handlePrintforedit = async()=>{
        try {
          setLoading(true);
          const getPDF = await getDyePDF(dyeId);
          setOpenPDFModal(true);
          const filepath=getPDF.data.returnpath
          setPdfData(filepath);
          
        } catch (error) {
          console.error("Error while printing:", error);
        }finally{
          setLoading(false);
      
        }
      }

useEffect(() => {
  const fetchData = async () => {
      try {
        setLoading(true);
          const getDyeData = await getDyeById(dyeId);
          setDye(true);

          const dyeData = getDyeData.data.dye;
          const CustNew = cust.filter((item) => item.value == dyeData.Cust_Name)[0];
          const parseDate = (dateString) => {
              if (dateString) {
                  const parts = dateString.split('/');
                  return `${parts[2]}-${parts[1]}-${parts[0]}`;
              }
              return null;
          };
          const sortedData = {
              Cert_Date: parseDate(dyeData.Cert_Date),
              Cust_Name: CustNew,
              Certificate_No: dyeData.Certificate_No,
              Referance_Id: dyeData.Referance_Id,
              Test_Spec: dyeData.Test_Spec,
              Applicable_Spec: dyeData.Applicable_Spec,
              Acceptance_Std: dyeData.Acceptance_Std,
              Scope_Exam: dyeData.Scope_Exam,
              Method: dyeData.Method,
              Penetrant: dyeData.Penetrant,
              Penetrant_time: dyeData.Penetrant_time,
              Batch_NoExam: dyeData.Batch_NoExam,
              Examination: dyeData.Examination,
              Developer: dyeData.Developer,
              Developer_time: dyeData.Developer_time,
              Application: dyeData.Application,
              Cleaner_Type: dyeData.Cleaner_Type,
              Black_Light: dyeData.Black_Light,
              Batch_No1: dyeData.Batch_No1,
              Batch_No2: dyeData.Batch_No2,
              UV_No: dyeData.UV_No,
              Witness_By: dyeData.Witness_By,
              technician: dyeData.technician,
              Location: dyeData.Location,
              designation: dyeData.designation,
          };
          formik.setValues(sortedData);

          const sortedRows = getDyeData.data.rows.map((row, index) => ({
              id: row.id,
              srNo: index + 1,
              ...row
          }));
          setRows(sortedRows);
      } catch (error) {
          console.log("Error in Fetching Data", error);
      }finally{
        setLoading(false);
      }
  };

  if (isEdit) {
      fetchData();
  }
}, [isEdit, dyeId, cust]);






 const formik = useFormik({
    initialValues:initalValues,
    validationSchema:Yup.object().shape({
      Cert_Date:Yup.date().required(),
      Certificate_No:Yup.number().integer().notRequired(),
      Test_Spec:Yup.string().notRequired(),
      Cust_Name:Yup.object().notRequired(),
      Referance_Id:Yup.string().required(),
      Acceptance_Std:Yup.string().notRequired(),
      Applicable_Spec:Yup.string().notRequired(),
      Scope_Exam:Yup.string().notRequired(),
      Method:Yup.string().notRequired('Method is required'),
      Penetrant:Yup.string().notRequired(),
      Penetrant_time:Yup.string().notRequired(),
      Batch_No1:Yup.string().notRequired(),
      Examination:Yup.string().notRequired(),
      Developer:Yup.string().notRequired(),
      Developer_time:Yup.string().notRequired(),
      Application:Yup.string().notRequired(),
      Cleaner_Type:Yup.string().notRequired(),
      Black_Light:Yup.string().notRequired(),
      Batch_No2:Yup.string().notRequired(),
      Batch_NoExam:Yup.string().notRequired(),
      UV_No:Yup.string().notRequired(),
      Witness_By:Yup.string().notRequired(),
      technician:Yup.string().notRequired("xxxx"),
      Location:Yup.string().notRequired("yyyyyyyyyy"),
      designation:Yup.string().notRequired(),

    }),
    onSubmit: async(values, { setErrors, setStatus, setSubmitting, resetForm }) => {
        try {
          setLoading(true);
          let response = {};
      if (rows.length ==0){
        setConfirmationMessage("Somthing Went Wrong!");
            setIsConfirmationOpen(true);
            setColor('error')
      }
          values.rows = rows;
          
          if (isEdit) {
            response = await updateDye(dyeId, values);
            setConfirmationMessage("Dye Updated successfully");
            setIsConfirmationOpen(true);
          } else {
            response = await createDye(values);
            console.log(response)
            if(response.status==200){
              console.log(response)
              setConfirmationMessage(response.data);
              setIsConfirmationOpen(true);
              setColor('green')
              setDye(true);
              resetForm();
              
              setRows([]);
            }else{
              setConfirmationMessage("Somthing Went Wrong!");
              setIsConfirmationOpen(true);
              setColor('error')
            }
            
            
            
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.response.data.message });
          setSubmitting(false);
        }finally{
          setLoading(false);
        }
      }
      
  });

  const handlep=()=>{
    if(dyeId){
      handlePrintforedit()
    }else{
      handlePrint()
    }
  }

  
const handlePrint= async()=>{
  
    try {
      setLoading(true)
      const response = await getPrint();
      
      setOpenPDFModal(true);
      const filepath=response.returnpath
    
      setPdfData(filepath);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }finally{
      setLoading(false)
    }

}

  return (
   
    <div>
      {loading && <LoadingSpinner/>}
        <div style={{display:"flex", flexDirection:"column",gap:"1rem"}}>
        <div style={{ display: 'flex', alignItems: 'center', gap:"0.5rem" }}>
        <FormControl style={{width:"180px"}}>
          <LocalizationProvider dateAdapter={AdapterDayjs}> 
          <DatePicker 
          name="Cert_Date" 
          format="DD/MM/YYYY" 
          label="Date" 
          errors={formik.errors}  
          value={formik.values.Cert_Date ? dayjs(formik.values.Cert_Date) : null}
          // onChange={(date) => {formik.setFieldValue('Cert_Date', date);}}
          onChange={(date) => {
            formik.setFieldValue(
              "Cert_Date",
              date ? dayjs(date).format('YYYY-MM-DD') : dayjs(formik.values.assignedDate).format('YYYY-MM-DD') || null
            );
          }} /> 
          </LocalizationProvider>
          </FormControl> 
            <TextField id="outlined-basic" style={{width:"39vw"}} label="Certificate No." name='Certificate_No' variant="outlined"  error={formik.errors.Certificate_No && formik.touched.Certificate_No}
                      helperText={formik.touched.Certificate_No ? formik.errors.Certificate_No : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Certificate_No} />
        </div>
        <div>
 <Autocomplete
          options={cust}
          getOptionLabel={(option) => option.label}
          onChange={(event, value) => formik.setFieldValue('Cust_Name', value || null)}
          value={formik.values.Cust_Name}
          renderInput={(params) => (
            <TextField
            {...params}
            sx={{width:"50vw"}}
            label="Customer Name"
            variant="outlined"
            name="Cust_Name"
            error={formik.errors.Cust_Name && formik.touched.Cust_Name}
            helperText={formik.touched.Cust_Name ? formik.errors.Cust_Name : ''}
            onBlur={formik.handleBlur}
          
            />
          )}
        />
      


       
        </div>
        <div>
            <TextField 
            style= {{width:"50vw"}}  
            id="outlined-basic" 
            label="Reference" 
            name='Referance_Id' 
            variant="outlined"  
            error={formik.errors.Referance_Id && formik.touched.Referance_Id}
            helperText={formik.touched.Referance_Id ? formik.errors.Referance_Id : ''}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.Referance_Id} />
          
        </div>
        </div>
        <br/> 
        <div >
        <div>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '1%' }}>

      
      
        <TextField
          id="outlined-basic"
          label="Part Description"
          name="Description"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Description}
        />

        <TextField
          id="outlined-basic"
          label="Qty"
          name="Qty"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Qty}
        />

        <TextField
          id="outlined-basic"
          label="Weight"
          name="Weight"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Weight}
        />

        <TextField
          id="outlined-basic"
          label="Accepted"
          name="Accepted"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Accepted}
        />

        <TextField
          id="outlined-basic"
          label="Rejected"
          name="Rejected"
          variant="outlined"
          style={{ width: '10vw' }}
          onChange={handleChange}
          value={formikValues.Rejected}
        />

        <FormControl>
          <InputLabel id="remarks-label">Remarks</InputLabel>
          <Select
            labelId="remarks-label"
            value={formikValues.Remarks}
            onChange={handleChange}
            label="Remarks"
            placeholder="Remarks"
            name="Remarks"
            style={{ width: '10vw' }}
          >
            <MenuItem value="No recordable Indication observed- Hence Accepted.">No recordable Indication observed- Hence Accepted.</MenuItem>
            <MenuItem value="Recordable indication observed hence not accepted.">Recordable indication observed hence not accepted.</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            {formikValues.Remarks !== 'No recordable Indication observed- Hence Accepted.' &&
             formikValues.Remarks !== 'Recordable indication observed hence not accepted.' &&
             formikValues.Remarks !== 'Other' && (
    <MenuItem value={formikValues.Remarks}>
      {formikValues.Remarks}
    </MenuItem>
  )}
          </Select>
          {formikValues.Remarks === 'Other' && (
            <TextField
              name="customRemarks"
              value={formikValues.customRemarks}
              onChange={handleChange}
              label="Custom Remark"
              variant="outlined"
              style={{ marginTop: '20px', width: '10vw' }}
            />
          )}
        </FormControl>
        <Button onClick={handleAddRow} style={{ backgroundColor: "red", color: "white" }} variant="contained">Add</Button>


      </div>
      <div style={{ marginBlock: "20px" }}>
        <DataGrid
          autoHeight
          density="compact"
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>
     
    </div>
        </div>
        
        
  
               <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr 1fr 1fr", gap:"2rem", marginBlock:"3vh"}}>
               <FormControl>
     <InputLabel id="testSpecification-label">Test Specification</InputLabel>
      <Select
        labelId="testSpecification-label"
        value={formik.values.Test_Spec}
        onChange={formik.handleChange}
        label="Test Specification"
        name="Test_Spec"
        error={formik.errors.Test_Spec && formik.touched.Test_Spec}
        onBlur={formik.handleBlur}
      >
        <MenuItem value="Finished Machine">Finished Machine</MenuItem>
        <MenuItem value="Row">Row</MenuItem>
        <MenuItem value="Semi-Finished">Semi-Finished</MenuItem>
        <MenuItem value="Welded"> Welded </MenuItem>
      </Select>
      {formik.errors.Test_Spec && formik.touched.Test_Spec  && (
    <FormHelperText error>
      {formik.errors.Test_Spec}
    </FormHelperText>
  )}
      </FormControl>
                  <TextField   id="outlined-basic" label="Acceptance Standard" name='Acceptance_Std' variant="outlined"  error={formik.errors.Acceptance_Std && formik.touched.Acceptance_Std}
                      helperText={formik.touched.Acceptance_Std ? formik.errors.Acceptance_Std : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Acceptance_Std} />
                  <TextField   id="outlined-basic" label="Application Specification" name='Applicable_Spec' variant="outlined"  error={formik.errors.Applicable_Spec && formik.touched.Applicable_Spec}
                      helperText={formik.touched.Applicable_Spec ? formik.errors.Applicable_Spec : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Applicable_Spec} />
                  <TextField   id="outlined-basic" label="Scope of Examination" name='Scope_Exam' variant="outlined"  error={formik.errors.Scope_Exam && formik.touched.Scope_Exam}
                      helperText={formik.touched.Scope_Exam ? formik.errors.Scope_Exam : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Scope_Exam} />

                    <FormControl >
                        <InputLabel id="examination-label">Examination</InputLabel>
                          <Select
                            labelId="examination-label"
                            value={formik.values.Examination}
                            onChange={formik.handleChange}
                            label="Examination"
                            name="Examination"
                            error={formik.errors.Examination && formik.touched.Examination}
                            onBlur={formik.handleBlur}
                          >
                            <MenuItem value="Black Light">Black Light</MenuItem>
                            <MenuItem value="Visual">Visual</MenuItem>
                          </Select>
                          {formik.errors.Examination && formik.touched.Examination  && (
                        <FormHelperText error>
                          {formik.errors.Examination}
                        </FormHelperText>
                      )}
                      </FormControl>


                  <TextField   id="outlined-basic" label="Penetrant" name='Penetrant' variant="outlined"  error={formik.errors.Penetrant && formik.touched.Penetrant}
                      helperText={formik.touched.Penetrant ? formik.errors.Penetrant : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Penetrant} />
                  <TextField   id="outlined-basic" label="Penetrant Dwell Time" name='Penetrant_time' variant="outlined"  error={formik.errors.Penetrant_time && formik.touched.Penetrant_time}
                      helperText={formik.touched.Penetrant_time ? formik.errors.Penetrant_time : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Penetrant_time} />
                  <TextField   id="outlined-basic" label="Batch No" name='Batch_No1' variant="outlined"  error={formik.errors.Batch_No1 && formik.touched.Batch_No1}
                      helperText={formik.touched.Batch_No1 ? formik.errors.Batch_No1 : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Batch_No1} />

                    <FormControl >
                        <InputLabel id="method-label">Method</InputLabel>
                          <Select
                            labelId="method-label"
                            value={formik.values.Method}
                            onChange={formik.handleChange}
                            label="Method"
                            name="Method"
                            error={formik.errors.Method && formik.touched.Method}
                            onBlur={formik.handleBlur}
                          >
                            <MenuItem value="Fluorescent Color Code">Fluorescent Color Code</MenuItem>
                            <MenuItem value="Visible">Visible</MenuItem>
                          </Select>
                          {formik.errors.Method && formik.touched.Method  && (
                        <FormHelperText error>
                          {formik.errors.Method}
                        </FormHelperText>
                      )}
                      </FormControl>
       

                 
                  <TextField   id="outlined-basic" label="Devloper Application" name='Developer' variant="outlined" error={formik.errors.Developer && formik.touched.Developer}
                      helperText={formik.touched.Developer ? formik.errors.Developer : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Developer} />
                  <TextField   id="outlined-basic" label="Devloping Time" name='Developer_time' variant="outlined" error={formik.errors.Developer_time && formik.touched.Developer_time}
                      helperText={formik.touched.Developer_time ? formik.errors.Developer_time : ''}
                       onBlur={formik.handleBlur}
                     onChange={formik.handleChange}
                     value={formik.values.Developer_time} />
                  <TextField   id="outlined-basic" label="Application" name='Application' variant="outlined"
                   error={formik.errors.Application && formik.touched.Application}
                   helperText={formik.touched.Application ? formik.errors.Application : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Application} />


                  <TextField   id="outlined-basic" label="Cleaner Type" name='Cleaner_Type' variant="outlined"
                   error={formik.errors.Cleaner_Type && formik.touched.Cleaner_Type}
                   helperText={formik.touched.Cleaner_Type ? formik.errors.Cleaner_Type : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Cleaner_Type} />
                  <TextField   id="outlined-basic" label="Black Light Intensity" name='Black_Light' variant="outlined"
                   error={formik.errors.Black_Light && formik.touched.Black_Light}
                   helperText={formik.touched.Black_Light ? formik.errors.Black_Light : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Black_Light} />
                  <TextField   id="outlined-basic" label="Batch No" name='Batch_No2' variant="outlined"
                   error={formik.errors.Batch_No2 && formik.touched.Batch_No2}
                   helperText={formik.touched.Batch_No2 ? formik.errors.Batch_No2 : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Batch_No2} />
                  <TextField   id="outlined-basic" label="Batch No" name='Batch_NoExam' variant="outlined"
                   error={formik.errors.Batch_NoExam && formik.touched.Batch_NoExam}
                   helperText={formik.touched.Batch_NoExam ? formik.errors.Batch_NoExam : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Batch_NoExam} />

                  <TextField   id="outlined-basic" label="U/V Serial No" name='UV_No' variant="outlined"
                   error={formik.errors.UV_No && formik.touched.UV_No}
                   helperText={formik.touched.UV_No ? formik.errors.UV_No : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.UV_No} />
                  <TextField   id="outlined-basic" label="Witness By" name='Witness_By' variant="outlined"
                   error={formik.errors.Witness_By && formik.touched.Witness_By}
                   helperText={formik.touched.Witness_By ? formik.errors.Witness_By : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Witness_By} />
                  <TextField   id="outlined-basic" label="Technician" name='technician' variant="outlined"
                   error={formik.errors.technician && formik.touched.technician}
                   helperText={formik.touched.technician ? formik.errors.technician : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.technician} />
                  <TextField   id="outlined-basic" label="Location" name='Location' variant="outlined"
                   error={formik.errors.Location && formik.touched.Location}
                   helperText={formik.touched.Location ? formik.errors.Location : ''}
                    onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.Location} />
                  <TextField id="outlined-basic" label="Designation" name='designation' variant="outlined" 
                        error={formik.errors.designation && formik.touched.designation}
                        helperText={formik.touched.designation ? formik.errors.designation : ''}
                         onBlur={formik.handleBlur}
                       onChange={formik.handleChange}
                       value={formik.values.designation}/>

              </div>
             
              
              <div style={{display:"flex", justifyContent:"flex-end",}}>
              <Button onClick={handlep} style={{ backgroundColor: dye ? "orange" : "gray", color: "white", marginInline: "0.7rem", opacity: dye ? 1 : 0.5 }} disabled={!dye}>PRINT</Button>

              <Button
                onClick={formik.handleSubmit}
                style={{
                  backgroundColor: "Green",
                  color: "white", 
                  marginInline: "0.7rem", 
                  
                }}
              >
                {dyeId === undefined ? "Save" : "Update"}
              </Button>

              <Button onClick ={handleBack} style={{backgroundColor:"red",color:"white", marginInline:"0.7rem"}}>CANCEL</Button>
              <Button onClick ={handleBack} style={{backgroundColor:"blue",color:"white"}}>BACK</Button>

                </div>
                <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>
        
        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>
      
        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={isConfirmationOpen}
        message={confirmationMessage}
      color={color}
        onClose={() => setIsConfirmationOpen(false)}
      />
    </div>
    
  )
}

export default CreateDyes;
