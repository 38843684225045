import {
  Paper,
  CardHeader,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CreateBill from "./CreateBill";

const AddBill = () => {
  const { billId } = useParams();
  const isEdit = billId === undefined ? false : true;
  return (
    <>
        <CardHeader
          avatar={
            <Typography>
             <h2>{billId === undefined ? "Add Bill" : "Edit Bill"}</h2>
            </Typography>
          }
        />
        <CardContent>
          <CreateBill billId={billId} isEdit={isEdit}/>
        </CardContent>

    </>
  );
};

export default AddBill;
