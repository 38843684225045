import React ,{useState,useEffect} from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useNavigate} from 'react-router-dom';
import {Dialog, DialogActions, DialogTitle, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { deleteSpark, getAllSparks, getSparkPDF } from '../../../lib/api-spark';
import { APP_BASE_PATH } from '../../../lib/api-base-paths';
import LoadingSpinner from '../../../components/common/LoadingSpinner';






const style = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height:160,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '100%',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function Spark() {
  
  const navigate= useNavigate();
  const handleOpen = () => 
  {
    navigate("/AddSpark");
  }
  const[rows,setRows]= useState([]);
  const[selectedSparkId,setSelectedSparkId] =useState(null);
  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const[searchText,setSearchText]=useState('');
  const [openPDFModal, setOpenPDFModal] = useState(false);
  const [ pdfData, setPdfData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePDFModalClose = ()=>{
    setPdfData(null)
    setOpenPDFModal(false)};


  const handleSearch= (e) =>
  {
    setSearchText(e.target.value);
  }
  const handleEdit= (sparkId) =>
  {
    navigate(`/EditSpark/${sparkId}`)
  }
  const handleOpen2 = (sparkId) =>
  {
    setOpen2(true);
    setSelectedSparkId(sparkId);
  }
  const handleDelete=async()=>
  {
    try{
      setLoading(true)
       const data= await deleteSpark(selectedSparkId)
       fetchData()
       
       setOpen2(false);
    }catch(error){
console.error("Error Deleting bills:", error);
    }finally{
      setLoading(false)
    }
  }


    const fetchData = async()=> {
      try {
        setLoading(true)
         const response = await getAllSparks();
         const jsonData =response.data.map((row,index)=>({
         id:row.id,
         srNo:index+1,
          ...row,
         }));
         setRows(jsonData);
      } catch (error) {
        console.log("Error in fetching Sparks:", error);
      }finally{
        setLoading(false)
      }
    };
    useEffect(()=> {
    fetchData();
    
  },[]);


  const filteredRows = rows.filter((row) => {
    const certificateNo = row.Certificate_No.toString(); // Ensure it's converted to string
    return certificateNo.toLowerCase().includes(searchText.toLowerCase());
  });



  const columns = [
    { field: 'srNo', headerName: 'Sr. No.', flex: 1 },
    { field: 'Cust_Name', headerName: 'Customer Name', flex: 1 },
    { field: 'Certificate_No', headerName: 'Certificate No', flex: 1 },
    { field: 'Cert_Date', headerName: 'Date', flex: 1 },
    { field: 'technician', headerName: 'Technician', flex: 1 },
    { field: 'action', headerName: 'Action', flex: 1 , renderCell: (params)=>(
      <div>
       
      <IconButton onClick={() => handleEdit(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton aria-label="print" onClick={()=> handlePrint(params.row.id)}>
          <PrintIcon color='warning'/>
        </IconButton>
      <IconButton onClick={() => handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) },
    
    
  ];

  const handlePrint = async(id)=>{
    try {
      setLoading(true)
      const getPDF = await getSparkPDF(id);
      setOpenPDFModal(true);
      const filepath=getPDF.data.returnpath
      setPdfData(filepath);
      
    } catch (error) {
      console.log("Error while printing:", error);
    }finally{
      setLoading(false)
    }
}

  return (
    <div>
      {loading && <LoadingSpinner/>}
          <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>Spark</h1>
    <div style={{widows:"100%", display: "flex",justifyContent:"end",marginTop:"25px" }}>
          <div style={{marginRight:"40px"}}>
                <Search>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      value={searchText}
                      onChange={handleSearch}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search>
            </div>
           <div>
                <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white"}} variant="Add Customer">Create Spark</Button>
           </div>
        
    </div>


    <div style={{paddingTop:"20px",height: 400, width: '100%' }}>
  <DataGrid
    density='compact'
    rows={filteredRows}
    columns={columns}
    pageSize={5}
    rowsPerPageOptions={[5, 10, 20]}
   
  />
</div>

<Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDelete}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>

        <Dialog
        open={openPDFModal}
        onClose={handlePDFModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
       
        maxWidth={1200}
      >
        <DialogTitle>
          Invoice
        </DialogTitle>

        <iframe title="pdf-view"   src={pdfData && pdfData ? `${APP_BASE_PATH}${pdfData}` : null }width="800px" height="800px"></iframe>

        <DialogActions>
          <Button onClick={handlePDFModalClose}>Close</Button>          
        </DialogActions>
      </Dialog>

</div>
  )
}

export default Spark;
