import React, { useState,useEffect } from 'react'
import Button from '@mui/material/Button';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import { Grid, Typography } from '@mui/material';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { createEquipment, createStandard, deleteEquipment, deleteStandard, getAllEquipment,getAllStandards, getStandardById,updateEquipment,updateStandard,getEquipmentById} from '../../lib/api-ut';

import Modal from '@mui/material/Modal';
import LoadingSpinner from '../../components/common/LoadingSpinner';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px', 
  height: 'flex', 
  borderRadius: '2%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.black, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
  },
  marginRight: theme.spacing(2),
  
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto', 
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function UT() {
  const[open,setOpen]= useState(false);
  const[open1,setOpen1]= useState(false);
  const handleOpen =()=>{
    setOpen(true);
  };
  const handleClose= ()=>
  {
    setOpen(false);
    setStandardName('');
    setErrorMessage('');
  };

  const handleOpen1 =()=>{
    setOpen1(true);
  };
  const handleClose1= ()=>
  {
    setOpen1(false);
    setEquipmentName('');
    setErrorMessage1('');
  };

  const[editS,setEditS]= useState(false);
  const[editE,setEditE]= useState(false);
  const handleOpenEditS = ()=>
  {
    setEditS(true);
  }
  const handleCloseEditS = ()=>
  {
    setEditS(false);
    setStandardName('');
    setErrorMessage('');
  }
  const handleOpenEditE= ()=>
  {
    setEditE(true);
  }
  const handleCloseEditE = ()=>
  {
    setEditE(false);
    setEquipmentName('');
    setErrorMessage1('')
  }


  const[searchTextStd,setSearchTextStd] = useState('');
  const[searchTextEqp,setSearchTextEqp] =useState('');
  const[standardName,setStandardName]=useState('');
  const[equipmentName,setEquipmentName]=useState('');
  const[standards,setStandards] = useState([]);
  const[equipments,setEquipments]=useState([]);
  const[sID,setSid]=useState(null);
  const[eID,setEid]=useState(null);
  const[errorMessage,setErrorMessage] = useState();
  const[errorMessage1,setErrorMessage1]= useState();
  const [loading, setLoading] = useState(false);

  const[open3,setOpen3] =useState(false);
  const handleClose3 = ()=>setOpen3(false);
  const handleOpen3 = (stdId) =>
  {
    setOpen3(true);
    setSid(stdId);

  }

  const[open2,setOpen2] =useState(false);
  const handleClose2 = ()=>setOpen2(false);
  const handleOpen2 = (eqpId) =>
  {
    setOpen2(true);
    setEid(eqpId);

  }

  

  
    const fetchAccpStd = async ()=>
    {
      try {
        setLoading(true)
        const data = await getAllStandards();
        const standardsWithIds = data.map((row,index)=>(
          {
            id:row.Standard_Id,
            srNo:index+1,
            ...row
          }
        ));
        setStandards(standardsWithIds);
        
      } catch (error) {
        console.log("Error Fetching Standards" , error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchAccpStd();
  }, []);
  


    const fetchEquipment = async ()=>
    {
      try {
        setLoading(true)
        const data = await getAllEquipment();
        const equipmentsWithIds = data.map((row,index)=>(
          {
            id:row.Equipment_Id,
            srNo:index+1,
            ...row
          }
        ));
        setEquipments(equipmentsWithIds);
        
      } catch (error) {
        console.log("Error Fetching Equipments " , error);
      }finally{
          setLoading(false)
        }
    };
    useEffect(() => {
    fetchEquipment();
  }, []);


  const handleAddStandard = async () =>
  {
   if(standardName.trim()==='')
   {
        setErrorMessage('Acceptance Standard cannot be left blank');
   }
   else
   {
    setErrorMessage('');
    try {
      setLoading(true)
      await createStandard({Acceptance_Std:standardName});
      handleClose();
      fetchAccpStd();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
            
        setErrorMessage(error.response.data.error);
    } else {
        console.error('Error adding material:', error);
    }    }finally{
          setLoading(false)
        }
   }
  };

  const handleAddEquipment = async () =>
  {
     if(equipmentName.trim() === '')
     {
        setErrorMessage1("Equipment Name cannot be left blank");
     }
     else
     {
      try {
        setLoading(true)
        await createEquipment({Equipment_Nm:equipmentName});
        handleClose1();
        fetchEquipment();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
            
          setErrorMessage1(error.response.data.error);
      } else {
          console.error('Error adding material:', error);
      }  }finally{
          setLoading(false)
        }
     }
  }

  

  const handleDeleteStandard = async() =>
  {
    try {
      setLoading(true)
      await deleteStandard(sID);
      setOpen3(false);
      fetchAccpStd();
      setSearchTextStd('');
      
    } catch (error) {
      console.log("Error in deleting standard ",error);
    }finally{
          setLoading(false)
        }
  }

  const handleDeleteEquipment = async() =>
  {
     try {
      setLoading(true)
      await deleteEquipment(eID);
      setOpen2(false);
      fetchEquipment();
      setSearchTextEqp('');
      
     } catch (error) {
      console.log("Error in deleting equipment ",error );
     }finally{
          setLoading(false)
        }
  }

  const getStandard = async (stdId) =>
  {
    handleOpenEditS();
    setSid(stdId);
    try
    {
      const standard = await getStandardById(stdId);
      const standard_name = standard.map((row)=>
      {
          return row.Acceptance_Std;
      })
      setStandardName(standard_name)
    }
    catch(error)
    {
      console.log("Error in getting standard by id" , error);
    }
    
  }

  const getEquipment = async(eqpId) =>
  {
    handleOpenEditE();
    setEid(eqpId);
    try
    {
      const equipment = await getEquipmentById(eqpId);
      const equipment_name = equipment.map((row)=>
      {
          return row.Equipment_Nm;
      })
      setEquipmentName(equipment_name)
    }
    catch(error)
    {
      console.log("Error in getting equipment by id" , error);
    }
    
  }

  const updateStd = async() =>
  {
      if(standardName.trim()==='')
      {
         setErrorMessage('Acceptance Standard cannot be left Blank');
      }
      else
      {
        setErrorMessage('');
        try
        {
          const updateStdData = 
          {
            Acceptance_Std :standardName
          };
          setLoading(true)
          await updateStandard(sID,updateStdData);
          handleCloseEditS();
          fetchAccpStd();
          setSearchTextStd('');
        }
        catch(error)
        {
          if (error.response && error.response.data && error.response.data.error) {
            
            setErrorMessage(error.response.data.error);
        } else {
            console.error('Error adding material:', error);
        }        }finally{
          setLoading(false)
        }
      }
  }

  const updateEqp = async() =>
  {
      if(equipmentName.trim()==='')
      {
           setErrorMessage1('Equipment Name cannot be left blank')
      }
      else
      {
        try
        {
          const updateEqpData = 
          {
            Equipment_Nm :equipmentName
          };
          setLoading(true)
          await updateEquipment(eID,updateEqpData);
          handleCloseEditE();
          fetchEquipment();
          setSearchTextEqp('');
        }
        catch(error)
        {
          if (error.response && error.response.data && error.response.data.error) {
            
            setErrorMessage1(error.response.data.error);
        } else {
            console.error('Error adding material:', error);
        }        }finally{
          setLoading(false)
        }
      }
  }

  const columns = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'Acceptance_Std', headerName: 'Acceptance Standard', flex: 2 },
    {field:'action',headerName:'Action', flex: 1, renderCell: (params)=>(
      <div>
      <IconButton onClick={()=>getStandard(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={()=>handleOpen3(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) }
    
  ];

  const columns2 = [
    { field: 'srNo', headerName: 'Sr.No.', flex: 1 },
    { field: 'Equipment_Nm', headerName: 'Equiptment Name', flex: 2 },
    {field:'action',headerName:'Action', flex: 1, renderCell: (params)=>(
      <div>
      <IconButton onClick={()=>getEquipment(params.row.id)}>
        <EditIcon sx={{color:'black'}}/>
      </IconButton>
      <IconButton onClick={()=>handleOpen2(params.row.id)}>
        <DeleteIcon sx={{color:'red'}}/>
      </IconButton>
    </div>
    ) }
    
  ];

  return (
    <Grid>
      {loading && <LoadingSpinner/>}
        <h1 style={{fontFamily:" Overpass, serif" , textAlign:"center", fontSize:"2rem"}}>UT</h1> 
    

    <div style={{widows:"100%", display: "flex",justifyContent:"space-between",marginTop:"30px",marginRight:"10px" }}>

    <div style={{ display:"flex"}}>
                    <Search style={{width:"25rem"}}>
                      
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchTextStd}
                          onChange={(e)=>setSearchTextStd(e.target.value)}
                        />
                      </Search>

                      <Button onClick={handleOpen} style={{backgroundColor:"red",color:"white",}} variant="contained">Add</Button>
                </div>


              <div style={{ display:"flex" }}>
                    <Search style={{width:"25rem"}}>
                        <SearchIconWrapper>
                          <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                          placeholder="Search…"
                          inputProps={{ 'aria-label': 'search' }}
                          value={searchTextEqp}
                          onChange={(e)=>setSearchTextEqp(e.target.value)}
                        />
                      </Search>
                      <Button onClick={handleOpen1} style={{backgroundColor:"red",color:"white"}} variant="contained">Add</Button>
                </div>
     </div>


    <div style={{ display:"flex",paddingTop:"20px",height: 400, width: '100%',gap:"10%" }}>
          <DataGrid
            density='compact'
            
            
            rows={standards.filter((standard) =>
              standard.Acceptance_Std && standard.Acceptance_Std.toLowerCase().includes(searchTextStd.toLowerCase())
            )}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
          
          />
          

          <DataGrid
              style={{marginLeft:"10px"}}
              density='compact'
              rows={equipments.filter((equipment) =>
                equipment.Equipment_Nm && equipment.Equipment_Nm.toLowerCase().includes(searchTextEqp.toLowerCase())
              )}
              columns={columns2}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
            
            />
</div>
      
<Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Acceptance Standard"
            size="small"
            variant="outlined"
            value={standardName}
            onChange={(e)=>setStandardName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" fullWidth onClick={handleAddStandard}>
              Save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
           onClick={handleClose}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>    



    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={open1}
      onClose={handleClose1}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
      
          
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Equiptment Name"
            size="small"
            variant="outlined"
            value={equipmentName}
            onChange={(e)=>setEquipmentName(e.target.value)}
            error={errorMessage1 !== ''}
            helperText={errorMessage1}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success"  onClick={handleAddEquipment}fullWidth>
              Save
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleClose1}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal>   

    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={editS}
      onClose={handleCloseEditS}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Acceptance Standard"
            size="small"
            variant="outlined"
            value={standardName}
            onChange={(e)=>setStandardName(e.target.value)}
            error={errorMessage !== ''}
            helperText={errorMessage}
            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" fullWidth onClick={updateStd}>
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
           onClick={handleCloseEditS}
           
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 

    <Modal
      sx={{ maxWidth: 700, margin: "auto", marginTop: 2 }}
      open={editE}
      onClose={handleCloseEditE}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={12}>
          <TextField
            className="customTextField"
            id="outlined-basic"
            label="Equipment Name"
            size="small"
            variant="outlined"
            value={equipmentName}
            onChange={(e)=>setEquipmentName(e.target.value)}
            error={errorMessage1 !== ''}
            helperText={errorMessage1}

            fullWidth
          />
        </Grid>
       
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <Button variant="contained" color="success" fullWidth onClick={updateEqp}>
              Update
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: 1 }}>
          <Button
            variant="contained"
            color="error"
           onClick={handleCloseEditE}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      </Box>
    </Modal> 

    
    
    <Modal
        keepMounted
        open={open2}
        onClose={handleClose2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteEquipment}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose2}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>

        <Modal
        keepMounted
        open={open3}
        onClose={handleClose3}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        >
        <Box sx={style}>
        
       <div style={{alignContent:'center',alignItems:'center',textAlign:'center',}}>
       <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
        Are You Sure To Delete ?
        </Typography><br/>
       <Button variant="contained" color="success" sx={{marginRight:1}} onClick={handleDeleteStandard}>
  Yes
      </Button><Button variant="contained" color="error"  onClick={handleClose3}>
  No
      </Button>
       </div>
      
        </Box>
        </Modal>
</Grid>
  )
}

export default UT;

